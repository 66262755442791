import React, {useEffect, useMemo, useState} from 'react';
import { Box, Grid, Typography } from '@mui/material';
import fahrzeugfront_img from '../../images/fahrzeugfront_vertikal.png';
import { ArrowDownward, ArrowUpward, Done } from '@mui/icons-material';
import {Einstellwerte} from "../../generated/car";

import useLaserSocket from "../../components/laserSocket";
import { useSettingsStore, useStationStore } from '../../store/store';
import { useTranslation } from 'react-i18next';

const COLOR_WARNING = 'red';

const Fahrzeugfront = () => {
    const { t } = useTranslation();

    const [refDistance, setRefDistance] = useState(1500);
    const [color, setColor] = useState(COLOR_WARNING)

    const setGlobalLIST = useSettingsStore(state => state.setLIST);
    const setGlobalLIST_Phase2 = useSettingsStore(state => state.setLIST_Phase2);


    const stationStore = useStationStore(state => state.station);
    // const setStationStore = useStationStore(state => state.setStation);

    const stationId = stationStore?.id?.toLowerCase() || "";
    // const stationDevices = stationStore.devices;
    const stationDevices = useMemo(() => {
        return stationStore.devices;
    }, [JSON.stringify(stationStore.devices)]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {distance, connect, disconnect} = useLaserSocket(stationId,0);
    
    const [icon, setIcon] = useState(<ArrowDownward sx={{height:'7vh', width:'5vw', color:COLOR_WARNING}} />)
    const [phase, setPhase] = useState('Phase 1');

    let einstellwert: Einstellwerte = useMemo(() => {
        let einstellwert: Einstellwerte = {};
        
        try {
            const json = localStorage.getItem("einstellwert");
            if(json){
                einstellwert = JSON.parse(json);
            }
        } catch (error) {
            
        }

        return einstellwert;

    }, []);

    useEffect(() => {

        if(stationDevices){
            for (const dev of stationDevices) {
                if(dev.type === "Distance" && dev.tags?.includes("left")){
                    connect(dev);
                }
            }
        }
       
    }, [connect, stationDevices]);


    useEffect(() => {
        const test = localStorage.getItem("Phase");
        
        if(test != null && test !== phase){
            setPhase(test);
        }

    }, [phase]);


    useEffect(() => {

        if (einstellwert != null) {
            if (einstellwert.werte != null) {
                let werte = JSON.parse(einstellwert.werte);

                console.log("DEBUG werte !!!!", werte);

                if(phase === "Phase 1"){
                    if(werte['Abstand_Kalibriertafel'] != null){
                    
                        if(typeof werte['Abstand_Kalibriertafel'] === 'string'){
                            setRefDistance(parseInt(werte['Abstand_Kalibriertafel'].substring(0, werte['Abstand_Kalibriertafel'].length - 2)));
                        }else{
                            setRefDistance(parseInt(werte['Abstand_Kalibriertafel']));
                        }
                    }
                }else{
                    if(werte['Abstand_Kalibriertafel_Phase2'] != null){
                    
                        if(typeof werte['Abstand_Kalibriertafel'] === 'string'){
                            setRefDistance(parseInt(werte['Abstand_Kalibriertafel_Phase2'].substring(0, werte['Abstand_Kalibriertafel_Phase2'].length - 2)));
                        }else{
                            setRefDistance(parseInt(werte['Abstand_Kalibriertafel_Phase2']));
                        }
                    }
                }
                
            }
        }

    }, [einstellwert, phase]);


    useEffect(() => {
        const distanceStr = String(distance).replaceAll(".","");

        if(parseInt(distanceStr) >= refDistance -20 && parseInt(distanceStr) <= refDistance +20){
            setColor('green');
            setIcon(<Done sx={{height:'7vh', width:'5vw', color:'green'}} />)
        }else{
                setColor(COLOR_WARNING);
            if (parseInt(distanceStr) > refDistance) {
                setIcon(<ArrowUpward sx={{height: '7vh', width: '5vw', color: COLOR_WARNING}}/>)
            }else {
                setIcon(<ArrowDownward sx={{height: '7vh', width: '5vw', color: COLOR_WARNING}}/>)
            }
        }

        if(phase === "Phase 1"){
            setGlobalLIST(distance + " mm");
        }else if(phase === "Phase 2"){
            setGlobalLIST_Phase2(distance + " mm");
        }
        else{
            setGlobalLIST(distance + " mm")
        }
        
    }, [distance, phase, refDistance, setGlobalLIST, setGlobalLIST_Phase2]);
    

    return (
        <Box sx={{ backgroundColor: 'lightgrey', width: '100vw', height: '100vh', justifyContent: 'center', alignContent: 'center', textAlign:'center', verticalAlign:'middle' }}>
            <Box sx={{height: '3vh'}}></Box>
                <Typography variant='h3' >{phase}</Typography>
                <Grid container direction={{xs: 'column', md: 'row'}} textAlign={'center'} justifyContent={'center'} alignItems={'center'} spacing={0}>
                    <Grid item xs={12} md={8}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <img src={fahrzeugfront_img} style={{transform:'rotate(180deg)', width: '40vh', height: '80vh'}} alt={t("carFront") || "carFront"} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant='h3' >{t("laserLeft")}:</Typography>
                        <Typography variant='h3' style={{color: color}}>{distance} mm</Typography>
                        {icon}
                    </Grid>
                </Grid>
        </Box>
    )
}


export default Fahrzeugfront;