import {Button, Grid, Typography} from '@mui/material';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import screenAutoImg from '../images/screen_auto_height.png';
import {ArrowDownward, ArrowUpward} from '@mui/icons-material';
import {io, Socket} from 'socket.io-client';
import {JobApi, PostTargetPosPosEnum} from "../generated/job";
import { useSettingsStore, useStationStore } from '../store/store';
import { useShallow} from "zustand/react/shallow";
import { getSocket } from '../utils/socket';
import { Einstellwerte } from '../generated/car';
import { useTranslation } from 'react-i18next';
// import {useAuth} from "oidc-react";
// import { useNavigate } from 'react-router-dom';

const STATIC_MIN_HEIGHT =  110+542+548;  // 1200mm


const multiPosTargets: number[] = [9, 10, 17];
const lhd_rhdPosTarget: number[] = [15];

const Target = () => {
    const { t } = useTranslation();

    const [height, setHeight] = useState(0);
    const [DBHeight, setDBHeight] = useState(STATIC_MIN_HEIGHT);
    
    const [showPositions, setShowPositions] = useState(false)
    const [textFieldValueUp, setTextFieldValueUp] = useState('');
    const [textFieldValueDown, setTextFieldValueDown] = useState('');
    
    const [showPositionsLhdRhd, setshowPositionsLhdRhd] = useState(false);

    const allowTelemetryUpdatesRef = useRef(true);

    let jobApi = useMemo(() => new JobApi(),[]);

    const globalJobId = useSettingsStore(state => state.jobId);

    const setGlobalHIST = useSettingsStore(state => state.setHIST);
   
    const stationStore = useStationStore(useShallow(state => state.station));
    // const setStationStore = useStationStore(state => state.setStation);

    const stationId = stationStore?.id?.toLowerCase();

    const stationDevices = useMemo(() => {
        return stationStore.devices;
    }, [JSON.stringify(stationStore.devices)]);

    const socketRef = useRef<Socket>();


    const [isConnected, setIsConnected] = useState<boolean>();
   
    let einstellwerteX: Einstellwerte = {};
        try {
            const json = localStorage.getItem("einstellwert");
            if(json){
                einstellwerteX = JSON.parse(json);

            }
        } catch (error) {
            
        }
    
    let einstellwerte:Einstellwerte = useMemo(() => {
        return einstellwerteX;

    }, [JSON.stringify(einstellwerteX)]);
        
    
    let werte: any = useMemo(() => {
        
        if (einstellwerte !== undefined) {
            if (einstellwerte.werte !== undefined) {
                const werte =  JSON.parse(einstellwerte.werte)
                console.log(werte);
                return werte;
            }
        }
        
        return null;

    }, [einstellwerte]);



    const jobId = globalJobId;

    let connected = isConnected ?? socketRef.current?.connected;

    // debug

    // useEffect(() => {
    //     console.log("mounted");
    // }, []);

    // useEffect(() => {
    //     console.log("jobApi changed",jobApi);
        
    // }, [jobApi]);
    // useEffect(() => {
    //     console.log("stationDevices changed",stationDevices);
        
    // }, [stationDevices]);
    // useEffect(() => {
    //     console.log("stationId changed",stationId);
        
    // }, [stationId]);
    // useEffect(() => {
    //     console.log("werte changed",werte);
        
    // }, [werte]);
    // useEffect(() => {
    //     console.log("jobId changed",jobId);
        
    // }, [jobId]);
    // useEffect(() => {
    //     console.log("connected changed",connected);
        
    // }, [connected]);
    


    const moveUp = () => {
        
        let heightToMove = 10;

        if(textFieldValueUp !== ''){
            heightToMove = parseInt(textFieldValueUp)

        }


        const newDBHeight = DBHeight + heightToMove
        setDBHeight(newDBHeight);
    }

    const moveDown = () => {

        let heightToMove = 10;

        if(textFieldValueDown !== ''){
            heightToMove = parseInt(textFieldValueDown)
        }


        const newDBHeight = DBHeight - heightToMove
        setDBHeight(newDBHeight);
    }

    const changeUp = (event: any) => {
        const inputValue = event.target.value;
        if(!isNaN(inputValue)){
            setTextFieldValueUp(inputValue);
            // setMoveUpHeight(height + parseInt(inputValue));
        }else{
            alert('Bitte geben Sie nur Zahlen ein. (In mm!)')
        }

    }

    const changeDown = (event: any) => {
        const inputValue = event.target.value;
        if(!isNaN(inputValue)){
            setTextFieldValueDown(event.target.value);
            // setMoveDownHeight(height - parseInt(event.target.value));
        }else{
            alert('Bitte geben Sie nur Zahlen ein. (In mm!)')
        }
        
    }


    const reset = () => {
        console.log("reset");

        allowTelemetryUpdatesRef.current = false;

        if(!socketRef.current){
            socketRef.current = getSocket(`https://data.data2work.com/${stationId}`);
        }
        
        if(stationDevices){
            for (const dev of stationDevices) {
                if(dev.type === "Table"){
    
                    socketRef.current.emit("pos", dev.id!, `{"pos": 0 }`)
    
                     break;
                }
            }
        }
        
        // return() =>{
            allowTelemetryUpdatesRef.current = true;

            if(socketRef.current){
                // socketRef.current.disconnect();
            }
        // }

    }

    const resetCallback = useCallback(reset, [stationDevices, stationId]);

    // useEffect(() => {
    //     console.log("resetCallback changed",resetCallback);
        
    // }, [resetCallback]);

    useEffect(() => {
        if(stationId != null){
            
            if(!socketRef.current){
                console.log("init socket");
                socketRef.current = getSocket(`https://data.data2work.com/${stationId}`);
            }
    
            return () => {
                
                resetCallback();
            };
        }

    }, [resetCallback, stationId]);



    const onTelemetry = useCallback((...data: any[]) => {
        
        if(data[0].includes('table')){
            console.log("data table", data);

            let json = JSON.parse(data[1])

            // console.log("setHeight", json['pos']);

            setHeight(json['pos']);
        }
    }, []);

    const onConnect = useCallback(() => {
        if (socketRef.current == null) {
            return;
        }
        
        socketRef.current.off('telemetry', onTelemetry);
        socketRef.current.on("telemetry", onTelemetry);
        
    }, [onTelemetry]);
    

    // Set up event listener for connection
    const handleConnect = () => {
        setIsConnected(true);
        // console.log('Socket connected');
    };

    const handleDisconnect = () => {
        setIsConnected(false);
        // console.log('Socket disconnected');
    };
      
    
    useEffect(() => {
        if(werte != null && werte !== undefined){
            console.log("MultiPosTarget:" + multiPosTargets);
            console.log(einstellwerte.kalibriertafelID);
            console.log("Höhe Kalibriertafel"+werte['Höhe_Kalibriertafel']);
            if(multiPosTargets.includes(einstellwerte.kalibriertafelID ?? -1)){
                setShowPositions(true);
                jobApi.postTargetPos({ "id": jobId,"pos": PostTargetPosPosEnum.Pos1}).then(r => {
                    console.log("r" + r);
                });
            }
            if(lhd_rhdPosTarget.includes(einstellwerte.kalibriertafelID ?? -1)){
                console.log("Targets ist ein lhd_rhd Target!");
                setshowPositionsLhdRhd(true);
                jobApi.postTargetPos({ "id": jobId,"pos": PostTargetPosPosEnum.Lhd}).then(r => {
                    console.log("r" + r);
                });
            }
            let h;
            if(typeof werte['Höhe_Kalibriertafel'] === 'string'){
                console.log("TRUE");
                h = werte['Höhe_Kalibriertafel'].substring(0, werte['Höhe_Kalibriertafel'].length - 2); // remove mm
                setDBHeight(parseInt(h));
            }else{
                h = werte['Höhe_Kalibriertafel']; 
                setDBHeight(parseInt(h));
                console.log("FALSE");
            }

        }
    }, [einstellwerte.kalibriertafelID, jobApi, jobId, werte]);

    
    useEffect(() => {

        if(stationId == null){
            return
        }

        if(socketRef.current == null){
            return;
        }
        
        if(!stationDevices){
            return;
        }

        const deviceHeight = DBHeight - STATIC_MIN_HEIGHT;

        console.log(`{"pos": ${deviceHeight} }`);

        for (const dev of stationDevices) {
            if(dev.type === "Table"){
                if(socketRef.current){
                    console.log("target emit pos", dev.id!, `{"pos": ${deviceHeight} }`);
                    
                    socketRef.current.emit("pos", dev.id!, `{"pos": ${deviceHeight} }`)
                }

                break;
            }
        }

    }, [DBHeight, stationDevices, stationId]);


    useEffect(() => {

        if(allowTelemetryUpdatesRef.current){
            setGlobalHIST(DBHeight.toString());
        }
        
    }, [DBHeight, setGlobalHIST]);

    useEffect(() => {

        if(allowTelemetryUpdatesRef.current){
            setGlobalHIST(height + STATIC_MIN_HEIGHT + " mm");

        }

    }, [height, setGlobalHIST]);
    
    

    useEffect(() => {

        if (socketRef.current == null) {
            return;
        }

        if(!connected){
            socketRef.current.off('connect', onConnect);
            socketRef.current.on("connect", onConnect);
    
    
            // Listen for connection and disconnection events
            socketRef.current.on('connect', handleConnect);
            socketRef.current.on('disconnect', handleDisconnect);
    
    
            return () => {
                if(socketRef.current){
                    console.log("clear telemetry", stationId);
    
                    socketRef.current.off('connect', onConnect);
            
                    socketRef.current.off('telemetry', onTelemetry);
    
                    socketRef.current.off('connect', handleConnect);
                    socketRef.current.off('disconnect', handleDisconnect);
                }
            }
            
        }
        else{
            onConnect();

            // Listen for connection and disconnection events
            socketRef.current.on('connect', handleConnect);
            socketRef.current.on('disconnect', handleDisconnect);

            return () => {
                if(socketRef.current){
                    console.log("clear telemetry", stationId);
    
                    socketRef.current.off('connect', onConnect);
            
                    socketRef.current.off('telemetry', onTelemetry);
    
                    socketRef.current.off('connect', handleConnect);
                    socketRef.current.off('disconnect', handleDisconnect);
                }
            } 
        }
        


    }, [connected, onConnect, onTelemetry, stationId]);

    const posButtonClick = (pos: PostTargetPosPosEnum) => {
        jobApi.postTargetPos({"id": jobId, "pos": pos}).then(r => {
            console.log("r:" + r);
        });
    }

    // console.log("render target");
    
    return (
        <Grid container sx={{ width: '100vw', height: '100vh' }} alignItems={'center'} textAlign={'center'}>
            <Grid item xs={6}>
                <Grid container direction='row'>
                    <Grid item xs={12}>
                        <img src={screenAutoImg} alt={t("target_Height") ?? "targetHeight"} style={{ width: "50vh", height: "50vh" }}></img>
                    </Grid>
                    {showPositions && (
                        <>
                            <Grid item xs={4}>
                                <Button variant='contained' onClick={() => posButtonClick(PostTargetPosPosEnum.Pos2)}>
                                    {t("pos2")}
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button variant='contained' onClick={() => posButtonClick(PostTargetPosPosEnum.Pos1)}>
                                    {t("pos1")}
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button variant='contained' onClick={() => posButtonClick(PostTargetPosPosEnum.Pos3)}>
                                    {t("pos3")}
                                </Button>
                            </Grid>
                        </>
                )}
                {showPositionsLhdRhd && (
                    <>
                        <Grid item xs={6}>
                            <Button variant='contained' onClick={() => posButtonClick(PostTargetPosPosEnum.Lhd)}>
                                {t("lhd")}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant='contained' onClick={() => posButtonClick(PostTargetPosPosEnum.Rhd)}>
                                {t("rhd")}
                            </Button>
                        </Grid>
                    </>
                )}
                </Grid>
            </Grid>
            <Grid item xs={6} sx={{ height: '100%' }}>
                <Grid container sx={{ width: '100%', height: '100%' }} direction="column" justifyContent={'space-evenly'} justifyItems={'space-evenly'} alignItems={'center'}>
                    <Grid item xs={2}>
                        <input type="text" style={{height: "5vh"}} value={textFieldValueUp} onChange={changeUp}></input>
                        <Button variant='contained' onClick={moveUp}>
                            <ArrowUpward sx={{height:'40px'}}/>
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant='h3'>{t("targetHeight")}: {DBHeight} mm</Typography>
                        <Typography variant='h3'>{t("actualHeight")}: {height + STATIC_MIN_HEIGHT} mm</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <input type="text" style={{height: "5vh"}} value={textFieldValueDown} onChange={changeDown} ></input>
                        <Button variant='contained' onClick={moveDown}>
                            <ArrowDownward sx={{height:'40px'}}/>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Target;