import { useAuth } from "oidc-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DataGrid, GridActionsCellItem, GridColDef, GridRowId, GridRowSelectionModel, GridRowsProp, GridToolbarContainer } from "@mui/x-data-grid";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import CreateUser from "./createUser";
import { Configuration, User, UserApi } from "../../generated/user";
import { useSnackbar } from "../../utils/snackbar";

interface ISelectUserProps {
    onSelectionChanged?: (selected:GridRowSelectionModel) => void;
}

const SelectUser = (props:ISelectUserProps) => {

    const auth = useAuth();

    const access_token = auth.userData?.access_token;

        const showSnackbar = useSnackbar();
    
    // const [Users, setUsers] = useState<User[]>();

    
    const [rows, setRows] = useState<GridRowsProp>();

    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);

    const handleDeleteClick = (id: GridRowId) => async () => {
    
        const deleted = await deleteUser(id.toString());
        
        if(deleted){
            //todo neu fetchen
            
            setRows((oldRows) => {
                return oldRows?.filter((row) => row.id !== id)
            });
        }
    };

    const deleteUser = async (userId: string) => {
    
            const userApi = new UserApi(new Configuration({'accessToken': access_token!}));
    
            try {
                // await UserApi.deleteUser({id: userId});
    
                showSnackbar(`User ${userId} gelöscht`, "success");
    
                return true;
                
            } catch (error) {
                console.log("error postUser", error);
                    
            }
    
            return false;
        }

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 300
        },
        // {
        //     field: 'type',
        //     headerName: 'Type',
        //     width: 200,
        //     editable: true,
        // },

        // {
        //     field: 'tags',
        //     headerName: 'Tags',
        //     width: 200,
        //     editable: true,
        // },
        

        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
            // const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
    
            // if (isInEditMode) {
            //     return [
            //     <GridActionsCellItem
            //         icon={<Save />}
            //         label="Save"
            //         sx={{
            //         color: 'primary.main',
            //         }}
            //         onClick={handleSaveClick(id)}
            //     />,
            //     <GridActionsCellItem
            //         icon={<Cancel/>}
            //         label="Cancel"
            //         className="textPrimary"
            //         onClick={handleCancelClick(id)}
            //         color="inherit"
            //     />,
            //     ];
            // }
    
            return [
                // <GridActionsCellItem
                //     icon={<Edit />}
                //     label="Edit"
                //     className="textPrimary"
                //     onClick={handleEditClick(id)}
                //     color="inherit"
                // />,

                <GridActionsCellItem
                    icon={<Delete />}
                    label="Delete"
                    onClick={handleDeleteClick(id)}
                    color="inherit"
                />,
            ];
            },
        },
    ];

    const fetchData = useCallback(
        async () => {
            const userApi = new UserApi(new Configuration({'accessToken': access_token!}));
                        
            let allUsers:User[] = [];

            try {
                allUsers = await userApi.getAllUsers();


            } catch (error) {
                console.log("error fetchData get", error);
            }

            if(allUsers != null){
                setRows(allUsers);
            }
        },
        [access_token]
    );


    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handleNewRows = () => {
        // save new User IDs to Workplace

        fetchData()

    }

    if(rows == null){
        return (
            <div>
                no items
            </div>
        );
    }

    return (
        <div>
            

            <DataGrid
                autoHeight
                rows={rows}
                columns={columns}
                // initialState={{
                //     pagination: {
                //         paginationModel: {
                //         pageSize: 5,
                //         },
                //     },
                // }}
                // pageSizeOptions={[5]}

                checkboxSelection={props.onSelectionChanged != null}
                disableRowSelectionOnClick

                slots={{
                    toolbar: EditToolbar,
                }}
                slotProps={{
                    toolbar: { handleNewRows },
                }}

                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);

                    if(props.onSelectionChanged){
                        props.onSelectionChanged(newRowSelectionModel)
                    }
                }}
                rowSelectionModel={rowSelectionModel}
            />
        </div>
    )
};

export default SelectUser;


interface EditToolbarProps {
    // setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    // setRowModesModel: (
    //     newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    // ) => void;


    handleNewRows: () => void;

}

function EditToolbar(props: EditToolbarProps) {
    // const { setRows, setRowModesModel } = props;

    const [open, setOpen] = React.useState(false);

    const [selected, setSelected] = useState<GridRowSelectionModel>();

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleClick = () => {
        // // const id = randomId();
        // const id = -1;

        // setRows((oldRows) => [...oldRows, { id, name: '', age: '', isNew: true }]);
        // setRowModesModel((oldModel) => ({
        //     ...oldModel,
        //     [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
        // }));

        setOpen(true);
    };


    const submitRef = useRef<() => Promise<string | null | undefined>>();

    const renderSelectDialog = () => {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                // PaperProps={{
                //     component: 'form',
                //     onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                //         event.preventDefault();
                //         const formData = new FormData(event.currentTarget);
                //         const formJson = Object.fromEntries((formData as any).entries());
                //         const email = formJson.email;
                //         console.log(email);
                //         handleClose();
                //     },
                // }}
                fullWidth
                maxWidth="xl"
            >
                <DialogTitle>New User</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Neuen User anlegen
                    </DialogContentText>
                    
                    <CreateUser 
                        registerSubmit={(fn) => {
                            submitRef.current = fn;
                        }}
                    />
                    

                </DialogContent>
                <DialogActions>
                    <Button 
                        color="primary"
                        variant="outlined"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>

                    <Button 
                        color="primary"
                        variant="contained"
                        type="button" 

                        // disabled={submitRef.current == null}
                        
                        onClick={async () => {

                            // if(selected && selected.length > 0){
                            //     props.handleNewRows(selected);

                            // }


                            if(submitRef.current){
                                const newId = await submitRef.current();

                                if(newId == null){
                                    
                                    return;
                                }
                            }

                            props.handleNewRows();

                            handleClose();

                        }}
                    >Add User</Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <GridToolbarContainer>
            <Button 
                color="primary" 
                startIcon={<Add />} 
                onClick={handleClick}
            >
                Add User
            </Button>

            {renderSelectDialog()}
        </GridToolbarContainer>
    );
}



