import {Box, Button, Card, Grid, TextField, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
// import {useNavigate} from "react-router-dom";
import { Address } from '../generated/pdf';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../utils/snackbar';

const Fulfillerdata = () => {
    // const navigate = useNavigate();

    const { t } = useTranslation();

    const showSnackbar = useSnackbar();




    const [monteur, setMonteur] = useState<Address>({
        firstName: '',
        lastName: '',
        company: '',
        address1: '',
        address2: '',
        city: '',
        postcode: '',
        country: '',
        state: '',
        email: '',
        phone: '',
    });

    


    useEffect(() => {
        const initialMonteurFromStorage = localStorage.getItem("monteur");
        let initialMonteur:Address|undefined;

        if(initialMonteurFromStorage){
            try {
                initialMonteur = JSON.parse(initialMonteurFromStorage)

                // aus 2 Feldern soll eins werden. deshalb den evtl gespeicherten alten Wert ins erste Feld übernehmen und das zweite Feld leeren
                if(initialMonteur?.address2){
                    initialMonteur.address1 += "\n" + initialMonteur.address2;

                    initialMonteur.address2 = "";
                }
            } catch (error) {
                
            }
        }
        
        if(initialMonteur !== undefined){
            setMonteur(initialMonteur)
        }
    }, []);

    const updateField = (e: any) => {
        setMonteur({
            ...monteur,
            [e.target.name]: e.target.value,
        });
    };

    const finish = () => {
        localStorage.setItem("monteur", JSON.stringify(monteur));

        console.log(monteur);
        // alert('Daten wurden lokal gespeichert.');

        showSnackbar(t("dataSavedLocally"), "success");
    };

    return (
        <Grid container alignItems={'center'} justifyContent={'center'} textAlign={'center'} spacing={0} sx={{ backgroundColor: 'lightgrey' }}>
            <Grid  item xs={12} justifyContent={'center'} alignItems={'center'}>

                <Card 
                    // style={{width: '99%'}}
                    sx={{ padding: { xs: '10px', md: '16px' } }}

                >
                    <Grid container alignItems={'center'} justifyContent={'center'} textAlign={'center'} spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{ textAlign: 'center', height: '8vh' }}>
                                <Typography variant='h4' mt={2}>{t("fulfiller")}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={2} alignItems={"left"} justifyContent={"left"}>

                        <Grid item xs={12} >
                            <Box>
                                <TextField 
                                    name={"company"} 
                                    value={monteur.company} 
                                    onChange={updateField}
                                    
                                    label={t("company")}
                                    variant="outlined" 
                                    
                                    fullWidth

                                    multiline
                                    // rows={2}
                                    minRows={2}
                                    maxRows={5}
                                    
                                />
                            </Box>
                        </Grid>

                        <Grid container item xs={12} spacing={2} alignItems={"left"} justifyContent={"left"} >
                            <Grid item xs={12} sm={6} >
                                <Box>
                                    <TextField 
                                        name={"firstName"} 
                                        value={monteur.firstName} 
                                        onChange={updateField}
                                        
                                        label={t("firstName")}
                                        variant="outlined" 
                                        
                                        fullWidth
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} >
                                <Box>
                                    <TextField 
                                        name={"lastName"} 
                                        value={monteur.lastName} 
                                        onChange={updateField}
                                        
                                        label={t("lastName")}
                                        variant="outlined" 
                                        
                                        fullWidth
                                    />
                                </Box>
                            </Grid>
                        

                        </Grid>

                        
                        <Grid container item xs={12} spacing={2} alignItems={"left"} justifyContent={"left"} >
                            <Grid item xs={12}>
                                <Box>
                                    <TextField 
                                        name={"address1"} 
                                        value={monteur.address1} 
                                        onChange={updateField}
                                        
                                        label={t("address1")}
                                        variant="outlined" 
                                        
                                        fullWidth

                                        multiline
                                        // rows={2}
                                        minRows={2}
                                        maxRows={5}
                                    />
                                </Box>
                            </Grid>
                            

                            {/* <Grid item xs={12} sm={6} md={4} lg={4} >
                                <Box>
                                    <TextField 
                                        name={"address2"} 
                                        value={monteur.address2} 
                                        onChange={updateField}
                                        
                                        label={t("address2")}
                                        variant="outlined" 
                                        
                                        fullWidth
                                    />
                                </Box>
                            </Grid> */}
                            
                            
                        </Grid>
                        
                       
                        <Grid container item xs={12} spacing={2} alignItems={"left"} justifyContent={"left"} >

                            <Grid item xs={12} sm={6} md={3} lg={3} >
                                <Box>
                                    <TextField 
                                        name={"country"} 
                                        value={monteur.country} 
                                        onChange={updateField}
                                        
                                        label={t("country")}
                                        variant="outlined" 
                                        
                                        fullWidth
                                    />
                                </Box>
                            </Grid>
                            
                            
                            <Grid item xs={12} sm={6} md={3} lg={3} >
                                <Box>
                                    <TextField 
                                        name={"postcode"} 
                                        value={monteur.postcode} 
                                        onChange={updateField}
                                        
                                        label={t("postcode")}
                                        variant="outlined" 
                                        
                                        fullWidth
                                    />
                                </Box>
                            </Grid>
                            
                            
                            <Grid item xs={12} sm={6} md={3} lg={3} >
                                <Box>
                                    <TextField 
                                        name={"city"} 
                                        value={monteur.city} 
                                        onChange={updateField}
                                        
                                        label={t("city")}
                                        variant="outlined" 
                                        
                                        fullWidth
                                    />
                                </Box>
                            </Grid>
                            
                            
                            <Grid item xs={12} sm={6} md={3} lg={3} >
                                <Box>
                                    <TextField 
                                        name={"state"} 
                                        value={monteur.state} 
                                        onChange={updateField}
                                        
                                        label={t("state")}
                                        variant="outlined" 
                                        
                                        fullWidth
                                    />
                                </Box>
                            </Grid>
                            
                        </Grid>
                        
                        <Grid item xs={12}>
                            <Box>
                                <TextField 
                                    name={"phone"} 
                                    value={monteur.phone} 
                                    onChange={updateField}
                                    
                                    label={t("phone")}
                                    variant="outlined" 
                                    
                                    fullWidth
                                />
                            </Box>
                        </Grid>
                        
                        
                        <Grid item xs={12}>
                            <Box>
                                <TextField 
                                    name={"email"} 
                                    value={monteur.email} 
                                    onChange={updateField}
                                    
                                    label={t("email")}
                                    variant="outlined" 
                                    
                                    fullWidth
                                />
                            </Box>
                        </Grid>




                        
                    </Grid>

                    <Box sx={{ height: '50px' }}></Box>
                    
                    <Grid item xs={12}>
                        <Button variant={"contained"} onClick={finish} sx={{padding: '15px'}} style={{width:300}}>{t("saveFulfillerdata")}</Button>
                    </Grid>
                    
                   

                    <Box sx={{ height: '50px' }}></Box>
                </Card>
            </Grid>
        </Grid>
      
        
    )
}

export default Fulfillerdata;