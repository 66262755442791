/* tslint:disable */
/* eslint-disable */
/**
 * Workplace
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Workplace,
} from '../models';

export interface AddDevicesToWorkplaceRequest {
    id: string;
    devices: Array<string>;
}

export interface DeleteWorkplaceRequest {
    id: string;
}

export interface GetWorplaceRequest {
    id: string;
}

export interface PostWorkplaceRequest {
    workplace?: Workplace;
}

/**
 * 
 */
export class WorkplaceApi extends runtime.BaseAPI {

    /**
     * Appends Devices to Workplace
     */
    async addDevicesToWorkplaceRaw(requestParameters: AddDevicesToWorkplaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Workplace>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addDevicesToWorkplace.');
        }

        if (requestParameters.devices === null || requestParameters.devices === undefined) {
            throw new runtime.RequiredError('devices','Required parameter requestParameters.devices was null or undefined when calling addDevicesToWorkplace.');
        }

        const queryParameters: any = {};

        if (requestParameters.devices) {
            queryParameters['devices'] = requestParameters.devices;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workplace/{id}/newdevices`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Appends Devices to Workplace
     */
    async addDevicesToWorkplace(requestParameters: AddDevicesToWorkplaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Workplace> {
        const response = await this.addDevicesToWorkplaceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Löscht den Workplace
     */
    async deleteWorkplaceRaw(requestParameters: DeleteWorkplaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteWorkplace.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workplace/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Löscht den Workplace
     */
    async deleteWorkplace(requestParameters: DeleteWorkplaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteWorkplaceRaw(requestParameters, initOverrides);
    }

    /**
     * Listet alle Workplace auf 
     */
    async getAllWorkplacesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Workplace>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workplaces`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Listet alle Workplace auf 
     */
    async getAllWorkplaces(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Workplace>> {
        const response = await this.getAllWorkplacesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Listet alle Workplace welche mit dem aktuellen Account verknüpft sind 
     */
    async getWorkplacesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Workplace>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workplace`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Listet alle Workplace welche mit dem aktuellen Account verknüpft sind 
     */
    async getWorkplaces(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Workplace>> {
        const response = await this.getWorkplacesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Liefert den aktuellen Workplace für die übergebene ID zurück
     */
    async getWorplaceRaw(requestParameters: GetWorplaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Workplace>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getWorplace.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workplace/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Liefert den aktuellen Workplace für die übergebene ID zurück
     */
    async getWorplace(requestParameters: GetWorplaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Workplace> {
        const response = await this.getWorplaceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Legt ein neuen Workplace an 
     */
    async postWorkplaceRaw(requestParameters: PostWorkplaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Workplace>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workplace`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.workplace,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Legt ein neuen Workplace an 
     */
    async postWorkplace(requestParameters: PostWorkplaceRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Workplace> {
        const response = await this.postWorkplaceRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
