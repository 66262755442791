import { Card, Grid } from "@mui/material";
import React from "react";
import SelectUser from "../../components/users/selectUsers";
// import CameraComponent from "../../components/camera/camera";

interface IUsersProps {

}

const Users = (props:IUsersProps) => {

    return (
        <Grid container alignItems={'center'} justifyContent={'center'} textAlign={'center'} spacing={0} sx={{ backgroundColor: 'lightgrey' }}>
            <Grid item xs={12} justifyContent={'center'} alignItems={'center'}>
                Users
                
                <Card 
                    sx={{ padding: { xs: '10px', md: '16px' } }}
                >
                    <SelectUser />



                    {/* <CameraComponent /> */}
                </Card>
            </Grid>
        </Grid>
    )
};

export default Users;
