import React, { useEffect, useState } from "react";
import { useAuth } from "oidc-react";
import { Configuration, Workplace, WorkplaceApi } from "../../generated/workplace";
import { Grid } from "@mui/material";
import WorkplaceList from "../../components/workplace/workplaceList";

interface IWorkplacesProps {

}

const Workplaces = (props:IWorkplacesProps) => {


    const auth = useAuth();

    const access_token = auth.userData?.access_token;

    const [workplaces, setWorkplaces] = useState<Workplace[]>();

    useEffect(() => {
        const fetchData = async () => {
            const worklaceApi = new WorkplaceApi(new Configuration({'accessToken': access_token!}));

            let allWorkplaces:Workplace[] = [];

            try {
                // const workplaces = await worklacApi.getAllWorkplaces();
                allWorkplaces = await worklaceApi.getWorkplaces();
            } catch (error) {
                console.log("error fetchData getAllWorkplaces", error);
            }

            if(allWorkplaces != null){
                setWorkplaces(allWorkplaces);
            }
        }

        fetchData();
    }, [access_token])


    if(workplaces == null){
        return (
            <div>
                no items
            </div>
        );
    }

    return (
        <Grid container alignItems={'center'} justifyContent={'center'} textAlign={'center'} spacing={0} sx={{ backgroundColor: 'lightgrey' }}>
            <Grid  item xs={12} justifyContent={'center'} alignItems={'center'}>
                Workplaces

                <WorkplaceList 
                    items={workplaces}
                />
            </Grid>
        </Grid>
        
    )
};

export default Workplaces;
