import { Box, Button, Card, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Einstellwerte } from "../generated/car";
import { useConfigStore, useSettingsStore, useStationStore, useSuperJobStore, useValueStore } from "../store/store";
import { JobApi } from "../generated/job";

import useTargets from "./useTargets";
import { useTranslation } from "react-i18next";

const ManualSelection = () => {

    const { t } = useTranslation();
    
    
    const superJob = useSuperJobStore(state => state.superJob);

    const configTarget = useConfigStore(state => state.config?.target_mapping);
    const setSuperJobValue = useSuperJobStore(state => state.setSuperJobValue);

    const globalMessTyp = useValueStore(state => state.messtyp);
    const setGlobalMessTyp = useValueStore(state => state.updateMesstyp);


    const globalJobId = useSettingsStore(state => state.jobId);

    const setGlobalIsMultiPhaseCalibration = useSettingsStore(state => state.setIsMultiPhaseCalibration);
    const setGlobalAlignmentType = useSettingsStore(state => state.setAlignmentType);
    const setGlobalJobId = useSettingsStore(state => state.setJobId);
    const setGlobalCalibration = useSettingsStore(state => state.setCalibration);
    const setGlobalHIST = useSettingsStore(state => state.setHIST);
    const setGlobalLIST = useSettingsStore(state => state.setLIST);
    const setGlobalRIST = useSettingsStore(state => state.setRIST);





    let jobApi: JobApi = new JobApi();

    const targets = useTargets(configTarget || []);

    // const [mHersteller, setMHersteller] = useState("");
    // const [mAbstandKalibriertafel, setMAbstandKalibriertafel] = useState("");
    // const [mAbstandKalibriertafelPhase2, setMAbstandKalibriertafelPhase2] = useState("");
    const [mTarget, setMTarget] = useState("");
    // const [mHoeheTarget, setMHoeheTarget] = useState("");
    const [radioMesstyp, setRadioMessTyp] = useState(''); // 1 = Radnabenmitte, 2 = Frontkamera, 3 = Fahrzeugfront
    const navigate = useNavigate();
    const [kalibrierung, setKalibrierung] = useState('');

    const stationStore = useStationStore(state => state.station);
    // const setStationStore = useStationStore(state => state.setStation);

    // const stationId = stationStore?.id?.toLowerCase();
    const stationDevices = stationStore.devices;

    const start = async () => {

        // console.log("IST MEHRPHASENKALIBRIERUNG: "+mehrphasenKalibrierung);
        localStorage.setItem("kba1",  "XXX");
        localStorage.setItem("kba2",  "XXX");

        // const isPhase2Filled = mAbstandKalibriertafelPhase2.trim() !== "";
        const isPhase2Filled = superJob.lsollphase2?.trim() && superJob.lsollphase2?.trim() !== "";
        
        let e: Einstellwerte;

        if (isPhase2Filled) {
            setGlobalIsMultiPhaseCalibration(true);
            e = {
                "id": 0,
                "kalibriertafelID": parseInt(mTarget),
                "messTypId": radioMesstyp === "Radnabenmitte" ? 3 : radioMesstyp === "Frontkamera" ? 4 : 2,
                // "werte": JSON.stringify({"Abstand_Kalibriertafel":  isNaN(Number(mAbstandKalibriertafel)) ? 0 : parseInt(mAbstandKalibriertafel),"Abstand_Kalibriertafel_Phase2": isNaN(Number(mAbstandKalibriertafelPhase2)) ? 0: parseInt(mAbstandKalibriertafelPhase2), "Höhe_Kalibriertafel": isNaN(Number(mHoeheTarget)) ? 0 : parseInt(mHoeheTarget)}),
                "werte": JSON.stringify({"Abstand_Kalibriertafel":  isNaN(Number(superJob.lsoll)) ? 0 : parseInt(superJob.lsoll ?? ""),"Abstand_Kalibriertafel_Phase2": isNaN(Number(superJob.lsollphase2)) ? 0: parseInt(superJob.lsollphase2 ?? ""), "Höhe_Kalibriertafel": isNaN(Number(superJob.hsoll)) ? 0 : parseInt(superJob.hsoll ?? "")}),
                "EinstellArt": {
                    "id": 0,
                    "bezeichnung": "XXX"
                }
            }
            
        } else {
            setGlobalIsMultiPhaseCalibration(false);
            e = {
                "id": 0,
                "kalibriertafelID": parseInt(mTarget),
                "messTypId": radioMesstyp === "Radnabenmitte" ? 3 : radioMesstyp === "Frontkamera" ? 4 : 2,
                // "werte": JSON.stringify({"Abstand_Kalibriertafel":  isNaN(Number(mAbstandKalibriertafel)) ? 0 : parseInt(mAbstandKalibriertafel), "Höhe_Kalibriertafel": isNaN(Number(mHoeheTarget)) ? 0 : parseInt(mHoeheTarget)}),
                "werte": JSON.stringify({"Abstand_Kalibriertafel":  isNaN(Number(superJob.lsoll)) ? 0 : parseInt(superJob.lsoll ?? ""), "Höhe_Kalibriertafel": isNaN(Number(superJob.hsoll)) ? 0 : parseInt(superJob.hsoll ?? "")}),
                "EinstellArt": {
                    "id": 0,
                    "bezeichnung": "XXX"
                }
            }
        }

        
        localStorage.setItem("einstellwert", JSON.stringify(e));

        const jobId = globalJobId > 0 ? globalJobId : undefined;

        try {
            const data = await jobApi.postJob({
                "job": {
                    "id": jobId,
                    "hsn": "XXX",
                    "name": "XXX",
                    "vin": "XXXXXX",
                    "state": "Created",
                    "additionalInfo": [{"name": "target", "value": mTarget}]
                }
            })
    
            if(data != null){
                if (data.id !== undefined) {
                    setGlobalJobId(data.id)
                    jobApi.changeState({
                        "id": data.id,
                        "state": "CalibrateDistance"
                    })
        
                    if(stationDevices){
                        for (const dev of stationDevices) {
                            if(dev.type === "Gateway" && dev.id != null){
                                jobApi.postMarry({
                                    "id": data.id,
                                    "device": dev.id,
                                }).then(r => console.log(r))
                            }
                        }
                    }
                }
            }
            
        } catch (error) {
            console.log("postError", error);
            
        }

               
        setGlobalHIST("");
        setGlobalLIST("");
        setGlobalRIST("");

        gotoCorrectPage(false);
    }

    

    const gotoCorrectPage = (isDB: boolean = true)  => {
        // console.log(radioMesstyp);
        // if(isDB){
        //     navigate("/einstellwerte")
        // }else{
            localStorage.setItem("Phase", "Phase 1");
            switch (radioMesstyp){
                case 'Fahrzeugfront':
                    navigate("/fahrzeugfront")
                    break
                case 'Radnabenmitte':
                    navigate("/radnabenmitte")
                    break
                case 'Frontkamera':
                    navigate("/frontkamera")
                    break
                default:
                    alert("Kein Messtyp ausgewählt")
            }
        // }

    }

    const hasLicense = useConfigStore(state => state.hasLicense);



    return (
        <Card 
            // style={{width: '99%', padding: '1%'}} 
            sx={{ padding: { xs: '10px', md: '16px' } }}
        >
            <Grid container alignItems={'center'} justifyContent={'center'} textAlign={'center'} spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography variant='h4' >{t("manualSelection")}</Typography>
                    </Box>
                </Grid>
            </Grid>
            <Grid container alignItems={'flex-start'}  spacing={2}>
                <Grid item xs={12} sm={6} md={2} lg={2} sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Box sx={{ textAlign: 'left' }}>
                        <Typography variant='h6' mt={2}>{t("car")}:</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={10} >
                    <Box>
                        {/* <TextField id="fahrzeug" label="Fahrzeug" variant="outlined" value={mHersteller} onChange={(event) => setMHersteller(event.target.value)} fullWidth/> */}
                        <TextField id="fahrzeug" label={t("car")} variant="outlined" value={superJob.fahrzeug} onChange={(event) => setSuperJobValue("fahrzeug", event.target.value)} fullWidth/>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={2} sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Box sx={{ textAlign: 'left' }}>
                        <Typography variant='h6' mt={2}>{t("distancePhase1_label1")}:</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} >
                    <Box>
                        <TextField 
                            id="abstand-kalibriertafel" 
                            label={t("distancePhase1_label2")} 
                            variant="outlined"
                            onChange={(event) => {
                                    // setMAbstandKalibriertafel(event.target.value);
                                    setSuperJobValue("lsoll", event.target.value);
                                    //setDisabled2(!event.target.value);
                                }
                            }
                            fullWidth
                            value={superJob.lsoll}
                            type="number"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={2} sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Box sx={{ textAlign: 'left' }}>
                        <Typography variant='h6' mt={2}>{t("distancePhase2_label1")}:</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} >
                    <Box>
                        <TextField 
                            id="abstand-kalibriertafel_phase2" label={t("distancePhase2_label2")} variant="outlined" 
                            onChange={(event) => {
                                const value = event.target.value;

                                // setMAbstandKalibriertafelPhase2(value);
                                setSuperJobValue("lsollphase2", value);
                            }}
                            fullWidth
                            value={superJob.lsollphase2}
                            type="number"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={2} sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Box sx={{ textAlign: 'left' }}>
                        <Typography variant='h6' mt={2}>{t("heightCalibrationTable_label1")}:</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Box>
                        <TextField 
                            id="hoehe-target" 
                            label={t("heightCalibrationTable_label2")} 
                            variant="outlined" 
                            disabled={kalibrierung === 'Distanzregelung'} 
                            onChange={(event) => {
                                // setMHoeheTarget(event.target.value)
                                setSuperJobValue("hsoll", event.target.value);
                            }} 
                            fullWidth 
                            value={superJob.hsoll} 
                            type="number"
                        />
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{height: '2vh'}}></Box>
            <Divider></Divider>
            <Box sx={{height: '2vh'}}></Box>
            <Grid container alignItems={'flex-start'}  spacing={2}>
                <Grid item xs={12} sm={6} md={2} lg={2} >
                    <Box sx={{ textAlign: 'left' }}>
                        <Typography variant='h6'>{t("calibration")}:</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} >
                    <FormControl fullWidth>
                        <RadioGroup
                            row
                            onChange={(event) => {
                                setKalibrierung(event.target.value);
                                setGlobalCalibration(event.target.value);
                                if (event.target.value === "Distanzregelung") {
                                    // Wenn "Distanzregelung" ausgewählt wurde, setMTarget(0) ausführen
                                    setMTarget("0");
                                    setGlobalMessTyp("Distanzregelung");
                                }
                                if (event.target.value === "Frontkamera") {
                                    setGlobalMessTyp("Frontkamera");
                                }

                            }}
                        >
                            <FormControlLabel value="Frontkamera" control={<Radio />} label={t("frontCamera")} />
                            <FormControlLabel value="Distanzregelung" control={<Radio />} label={t("distanceRule")} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={2}>
                    <Box sx={{ textAlign: 'left' }}>
                        <Typography variant='h6'>{t("alignment")}:</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <FormControl fullWidth>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(event) => {
                                setRadioMessTyp(event.target.value)
                                if (event.target.value === "Radnabenmitte") {
                                    setGlobalAlignmentType(event.target.value)
                                }
                                if (event.target.value === "Frontkamera") {
                                    setGlobalAlignmentType(event.target.value)
                                }
                                if (event.target.value === "Fahrzeugfront") {
                                    setGlobalAlignmentType(event.target.value)
                                }
                            }

                            }
                        >
                            <FormControlLabel value="Radnabenmitte" control={<Radio />} label={t("wheelHubCenter")} />
                            
                            {hasLicense("alignment-frontcamera") && 
                                <FormControlLabel value="Frontkamera" control={<Radio />} label={t("frontCamera")} />
                            }

                            <FormControlLabel value="Fahrzeugfront" control={<Radio />} label={t("carFront")} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Box sx={{height: '2vh'}}></Box>
            <Divider></Divider>
            <Box sx={{height: '2vh'}}></Box>
            <Grid container spacing={3} justifyContent={'center'} alignItems={'flex-start'}>
            <Grid item xs={12} lg={2}>
                <Box sx={{ textAlign: 'left' }} justifyContent={''}>
                    <Typography variant='h6'>{t("target")}:</Typography>
                </Box>
            </Grid>
            


            <Grid item lg={10}>
                <Card style={{backgroundColor: 'lightgray', justifyContent: 'center', alignItems: 'center', display: 'flex', width: 'fullWidth'}}>
                    <FormControl
                        disabled={kalibrierung === 'Distanzregelung'}
                    >
                    <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(event) => setMTarget(event.target.value)}
                            style={{ justifyContent: 'center', alignItems: 'center' }}

                            value={mTarget}
                        >
                            {targets.map((image, index) => {
                                return (
                                    <FormControlLabel
                                        key={image.id}
                                        value={image.id}
                                        control={<Radio />}
                                        label={
                                            <div style={{ textAlign: 'center' }}>
                                                <img
                                                    src={image.src}
                                                    alt={image.alt}
                                                    style={{ width: "90px", height: "60px", border: '2px solid #ccc' }}
                                                />
                                                <Typography variant="body2" component="p">
                                                    {image.name}
                                                </Typography>
                                            </div>
                                        }
                                    />
                                );
                            })}
                        </RadioGroup>
                    </FormControl>
                </Card>
            </Grid>


            <Grid item xs={12} lg={6}>
                    {/* <Button variant='contained' disabled={!superJob.fahrzeug||!mAbstandKalibriertafel || !radioMesstyp || !kalibrierung || (globalMessTyp === "Frontkamera" && !mTarget) } onClick={start} sx={{padding: '15px'}} fullWidth>Start</Button> */}
                    <Button variant='contained' disabled={!superJob.fahrzeug||!superJob.lsoll || !radioMesstyp || !kalibrierung || (globalMessTyp === "Frontkamera" && !parseInt(mTarget)) } onClick={start} sx={{padding: '15px'}} fullWidth>{t("start")}</Button>
            </Grid>
            </Grid>    
        </Card>
    );
 };

 export default ManualSelection;