import { Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import { Configuration, Workplace, WorkplaceApi } from "../../generated/workplace";

import Box from '@mui/material/Box';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowEditStopReasons, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridRowParams, GridRowsProp, GridToolbarContainer } from '@mui/x-data-grid';
import { useCallback, useRef, useState } from "react";
import { Add, Cancel, Delete, Details, Edit, Save } from "@mui/icons-material";
import WorkplaceDetail from "./workplaceDetail";
import { Link, useNavigate, useRoutes } from "react-router-dom";
import CreateWorkplace from "./createWorkplace";
import { useAuth } from "oidc-react";
import { useSnackbar } from "../../utils/snackbar";



interface IWorkplaceListProps {
    items:Workplace[];
}

const WorkplaceList = (props:IWorkplaceListProps) => {
    const {items} = props;
    const [rows, setRows] = useState<GridRowsProp >(items);

    let navigate = useNavigate();

    const showSnackbar = useSnackbar();

    const auth = useAuth();

    const access_token = auth.userData?.access_token;
   
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

    const handleDetailClick = (id: string) => () => {
        navigate(`/workplace/${id}`)
        
    };
  
    const handleDeleteClick = (id: GridRowId) => async () => {

        const deleted = await deleteWorkplace(id.toString());
        
        if(deleted){
            //todo neu fetchen
            
            setRows(rows.filter((row) => row.id !== id));
        }
    };
  

    
    const deleteWorkplace = async (workplaceId: string) => {

        const workplaceApi = new WorkplaceApi(new Configuration({'accessToken': access_token!}));

        try {
            await workplaceApi.deleteWorkplace({id: workplaceId});

            showSnackbar(`Workplace ${workplaceId} gelöscht`, "success");

            return true;
            
        } catch (error) {
            console.log("error postWorkplace", error);
                
        }

        return false;
    }


    const handleNewRows = () => {
        // save new DEvice IDs to Workplace

        // fetchData()

    }
    
    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 200
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 200,
            editable: true,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id, row }) => {
                // const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        
                // if (isInEditMode) {
                //     return [
                //     <GridActionsCellItem
                //         icon={<Save />}
                //         label="Save"
                //         sx={{
                //         color: 'primary.main',
                //         }}
                //         onClick={handleSaveClick(id)}
                //     />,
                //     <GridActionsCellItem
                //         icon={<Cancel/>}
                //         label="Cancel"
                //         className="textPrimary"
                //         onClick={handleCancelClick(id)}
                //         color="inherit"
                //     />,
                //     ];
                // }
        
                return [
                    // <GridActionsCellItem
                    //     icon={<Edit />}
                    //     label="Edit"
                    //     className="textPrimary"
                    //     onClick={handleEditClick(id)}
                    //     color="inherit"
                    // />,
                    <GridActionsCellItem
                        icon={<Edit />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleDetailClick(row.id)}
                        color="inherit"
                    >
                        <Link to={'/workplace/${}'} />
                    </GridActionsCellItem>,
                    <GridActionsCellItem
                        icon={<Delete />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];


    return (
        <Card 
            sx={{ padding: { xs: '10px', md: '16px' } }}
        >
            <Grid container alignItems={'center'} justifyContent={'center'} textAlign={'center'} spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            autoHeight
                            rows={rows}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                    pageSize: 5,
                                    },
                                },
                            }}
                            pageSizeOptions={[5]}
                            checkboxSelection
                            disableRowSelectionOnClick

                            slots={{
                                toolbar: EditToolbar,
                            }}
                            slotProps={{
                                toolbar: { handleNewRows },
                            }}

                        />
                    </Box>
                </Grid>
            </Grid>
        </Card>
        // <div >
        //     WorkplaceList

            

        //     {/* {items.map((item) => {
        //         return (
        //             <WorkplaceListItem item={item} />
        //         );
        //     })} */}
        // </div>
    )
};

export default WorkplaceList;



interface EditToolbarProps {

    handleNewRows: () => void;
}

function EditToolbar(props: EditToolbarProps) {

    const [open, setOpen] = useState(false);

    // const [selected, setSelected] = useState<GridRowSelectionModel>();

    // const handleClickOpen = () => {
    //   setOpen(true);
    // };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleClick = () => {
        // // const id = randomId();
        // const id = -1;

        // setRows((oldRows) => [...oldRows, { id, name: '', age: '', isNew: true }]);
        // setRowModesModel((oldModel) => ({
        //     ...oldModel,
        //     [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
        // }));

        setOpen(true);
    };


    const submitRef = useRef<() => Promise<string | null | undefined>>();

    const renderSelectDialog = () => {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                // PaperProps={{
                //     component: 'form',
                //     onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                //         event.preventDefault();
                //         const formData = new FormData(event.currentTarget);
                //         const formJson = Object.fromEntries((formData as any).entries());
                //         const email = formJson.email;
                //         console.log(email);
                //         handleClose();
                //     },
                // }}
                fullWidth
                maxWidth="xl"
            >
                <DialogTitle>New Workplace</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Neuen Workplace anlegen
                    </DialogContentText>
                    
                    <CreateWorkplace 
                        registerSubmit={(fn) => {
                            submitRef.current = fn;
                        }}
                    />
                    

                </DialogContent>
                <DialogActions>
                    <Button 
                        color="primary"
                        variant="outlined"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>

                    <Button 
                        color="primary"
                        variant="contained"
                        type="button" 

                        // disabled={submitRef.current == null}
                        
                        onClick={async () => {

                            // if(selected && selected.length > 0){
                            //     props.handleNewRows(selected);

                            // }


                            if(submitRef.current){
                                const newId = await submitRef.current();

                                if(newId == null){
                                    
                                    return;
                                }
                            }

                            props.handleNewRows();

                            handleClose();

                        }}
                    >Add Workplace</Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <GridToolbarContainer>
            <Button 
                color="primary" 
                startIcon={<Add />} 
                onClick={handleClick}
            >
                Add Workplace
            </Button>

            {renderSelectDialog()}
        </GridToolbarContainer>
    );
}
