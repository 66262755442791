import jwtDecode from 'jwt-decode';
import { AuthContextProps } from 'oidc-react';

interface DecodedToken {
  realm_access?: {
    roles: string[];
  };
}

export const hasRole = (role: string, auth:AuthContextProps): boolean => {
    if (!auth || !auth.userData || !auth.userData.access_token) {
      return false;
    }

    const decodedToken = jwtDecode<DecodedToken>(auth.userData.access_token);
    return decodedToken?.realm_access?.roles?.includes(role) || false;
};