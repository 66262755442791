import { Button, Paper, Stack } from "@mui/material";
import DeviceList from "../devices/deviceList";
import { Device, Workplace } from "../../generated/workplace";

interface IWorkplaceDetailProps {
    item: Workplace;
    saveNewDevicesToWorkplace: (newDevices:string[]) => void;
}

const WorkplaceDetail = (props: IWorkplaceDetailProps) => {
    const {item} = props;

    // const {
    //     control,
    //     handleSubmit,
    //     formState: { isValid },
    // } = useForm({
    //     defaultValues: row,
    //     mode: 'onChange',
    // });

    // const onSubmit = (data: GridRowModelUpdate) => {
    //     apiRef.current.updateRows([data]);
    //     apiRef.current.toggleDetailPanel(row.id);
    // };

    return (
        <Stack
            sx={{ py: 2, height: '100%', boxSizing: 'border-box' }}
            direction="column"
        >
            {/* <Paper sx={{ flex: 1, mx: 'auto', width: '100%', p: 1 }}> */}
                {/* <Stack
                    justifyContent="space-between"
                    // onSubmit={handleSubmit(onSubmit)}
                    sx={{ height: 1 }}
                > */}

                    <DeviceList 
                        items={item.devices ?? []} 
                        saveNewDevicesToWorkplace={props.saveNewDevicesToWorkplace}
                    
                    />
                    
                    {/* <div>
                        <Button
                            type="submit"
                            variant="outlined"
                            size="small"
                            // disabled={!isValid}
                            
                        >
                            Save
                        </Button>
                    </div> */}
                {/* </Stack> */}
            {/* </Paper> */}
        </Stack>
    );
};

export default WorkplaceDetail;
