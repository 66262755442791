import React from "react";
import SelectDevice from "./selectDevice";
import { Card, Grid } from "@mui/material";

interface IDevicesProps {

}

const Devices = (props: IDevicesProps) => {

    return (
        <Grid container alignItems={'center'} justifyContent={'center'} textAlign={'center'} spacing={0} sx={{ backgroundColor: 'lightgrey' }}>
            <Grid item xs={12} justifyContent={'center'} alignItems={'center'}>
                Devices
                
                <Card 
                    sx={{ padding: { xs: '10px', md: '16px' } }}
                >
                    <SelectDevice
                        // onSelectionChanged={(selected) => {
                        //     console.log("onSelectionChanged", selected);
                        //     setSelected(selected)
                        // }}
                    />

                </Card>
            </Grid>
        </Grid>
    )
};

export default Devices;
