import { Box, Grid, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Configuration, User , UserApi} from "../../generated/user";
import { useAuth } from "oidc-react";
import { useSnackbar } from "../../utils/snackbar";


// Inspired from https://github.com/microsoft/TypeScript/issues/30611#issuecomment-570773496
// function getEnumKeys<T extends string, TEnumValue extends string | number,>(enumVariable: { [key in T]: TEnumValue }) {
//     const keys = Object.keys(enumVariable);
    
//     const filtered = keys.filter((key) => {
//         return key !== "UnknownDefaultOpenApi";
//     });
    
//     return filtered as Array<T>;
// }

interface ICreateUserProps {
    registerSubmit? : (fn: () => Promise<string | null | undefined>) => void;
}

const CreateUser = (props:ICreateUserProps) => {

    const [User, setUser] = useState<User>({});
    
    const [tempTag, setTempTag] = useState<string>("");

    const showSnackbar = useSnackbar();


    // const [id, setId] = useState<string>("");

    // const [tags, setTags] = React.useState<readonly string[]>([
    //     // "left",
    //     // "right"
    // ]);

    const auth = useAuth();

    const access_token = auth.userData?.access_token;

    const createNewUser = async () => {

        const userApi = new UserApi(new Configuration({'accessToken': access_token!}));

        let result:User|null = null;
        try {
            result = await userApi.postUser({user: User});
            
        } catch (error) {
            console.log("error postUser", error);

            showSnackbar(`User ${User.id} konnte nicht angelegt werden`, "error");
                
        }

        if(result){

            showSnackbar(`User ${result.id} angelegt`, "success");
            return result.id;
        }
        
        // dummy debug
        return User.id;

        return null;
    }

    const createNewUserCallback = useCallback(createNewUser, [access_token, User, showSnackbar]);


    const { registerSubmit } = props;

    useEffect(() => {
        if(registerSubmit){
            registerSubmit(createNewUserCallback)
        }
    }, [createNewUserCallback, registerSubmit]);


    // const handleDeleteTag = (chipToDelete: string) => () => {
    //     // setTags((chips) => chips.filter((chip) => chip !== chipToDelete));

    //     setUser((oldUser) => {
    //         return {
    //             ...oldUser,
    //             tags: oldUser.tags?.filter((chip) => chip !== chipToDelete)
    //         }
    //     });
    // };

    // const handleAddTag = (tag:string) => {
    //     if(tag != null && tag.length > 0 && (User.tags ?? [] ).indexOf(tag) === -1){
    //         // setTags((chips) => {
    //         //     const newChips = [...chips];
    //         //     newChips.push(tag);

    //         //     return newChips;
    //         // });

    //         setUser((oldUser) => {
    //             const newTags = [...oldUser.tags ?? []];
    //             newTags.push(tag);

    //             return {
    //                 ...oldUser,
    //                 tags: newTags
    //             }
    //         });

    //         setTempTag("");
    //     }
    // }
    

    return (
        <div>
            CreateUser
            
            <Grid container spacing={2} paddingTop={2}>

                <Grid item xs={12}>
                    <Box>
                        <TextField 
                            name="id" 
                            label="ID" 
                            variant="outlined" 
                            onChange={(event) => {
                                const value = event.target.value;

                                // setId(value);

                                setUser((oldUser) => {
                                    return {
                                        ...oldUser,
                                        id: value
                                    }
                                });
                            }}

                            fullWidth
                            value={User.id}
                        />
                    </Box>
                </Grid>

                {/* <Grid item xs={12}>
                    <Box>
                        <FormControl fullWidth>
                            <InputLabel id="type-label">Type</InputLabel>

                            <Select 
                                value={User.type ?? ""}
                                fullWidth
                                labelId="type-label"
                                label="Type"

                                onChange={(event: SelectChangeEvent) => {
                                    const value = event.target.value;
                                    
                                    setUser((oldUser) => {
                                        return {
                                            ...oldUser,
                                            type: value as UserTypeEnum
                                        }
                                    });
                                }}
                            >
                                {getEnumKeys(UserTypeEnum).map((key, index) => (
                                    <MenuItem key={index} value={UserTypeEnum[key]}>
                                        {key}
                                    </MenuItem>
                                    
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid> */}

                {/* <Grid item xs={12}>
                    <Box>
                        
                        <Paper
                            sx={{
                                p: 0.5,
                                m: 0,
                            }}
                        >
                            <List
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'left',
                                    flexWrap: 'wrap',
                                    listStyle: 'none',
                                    p: 0.5,
                                    m: 0,
                                    '& .MuiListItem-root': {
                                        width: "auto"
                                    }
                                }}
                            >
                                {User.tags?.map((data) => {
                                    let icon;


                                    return (
                                        <ListItem key={data}>
                                            <Chip
                                                icon={icon}
                                                label={data}
                                                onDelete={handleDeleteTag(data)}
                                            />
                                        </ListItem>
                                    );
                                })}

                            </List>
                        
                            <Grid container paddingTop={2}>
                                <Grid item xs={8} sm={10}>
                                    <TextField 
                                        name="tag" 
                                        label="Tag" 
                                        variant="outlined" 
                                        onChange={(event) => {
                                            const value = event.target.value;

                                            setTempTag(value);
                                        }}

                                        fullWidth

                                        value={tempTag}
                                        
                                    />
                                
                                </Grid>

                                <Grid item xs={4} sm={2}>
                                    <Button 
                                        color="primary"
                                        variant="outlined"
                                        sx={
                                            {
                                                height: "100%"
                                            }
                                        }
                                        fullWidth
                                        
                                        onClick={() => {
                                            handleAddTag(tempTag);
                                        }}
                                    >Add Tag</Button>
                                </Grid>

                            </Grid>
                        </Paper>

                    </Box>
                </Grid> */}


            </Grid>



        </div>
    )
};

export default CreateUser;
