import React, { useEffect, useMemo, useState } from "react";
import { useSettingsStore } from "../../store/store";
import { Card, Grid } from "@mui/material";

interface IImageListProps {

}

const ImageList = (props:IImageListProps) => {

    const globalJobId = useSettingsStore(state => state.jobId);
    

    
    const imagesString = localStorage.getItem("images");

    // const images: string[] = useMemo(() => {
    //     if(imagesString){
    //         const data = JSON.parse(imagesString);

    //         if(data[globalJobId]){
    //             return data[globalJobId];
    //         }
    //     }

    //     return [];
    // }, [imagesString, globalJobId]);


    
    const [images, setImages] = useState<string[]>([]);

    useEffect(() => {
        if(imagesString){
            const data = JSON.parse(imagesString);

            if(data[globalJobId]){
                setImages(data[globalJobId]);
            }
        }
    }, [globalJobId, imagesString])


    const renderImage = (base64Image:string, index?:number) => {
        
        if(!base64Image){
            return (
                <div>no image</div>
            );
        }
        
        return (
            <Grid item xs={12} sm={6} md={3}>
                <Card>
                    <img src={`data:image/jpeg;base64,${base64Image}`} alt={`img ${index}`} style={{maxHeight:"100%", maxWidth:"100%"}}/>
                </Card>
            </Grid>
        );
    }

    return (
        <Grid container spacing={2} columns={12}>
            {images &&
            
                images.map((image, index) => {
                    return renderImage(image, index);
                })
            }
        </Grid>
    )
};

export default ImageList;
