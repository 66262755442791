import { Box, Button, Chip, FormControl, Grid, InputLabel, List, ListItem, MenuItem, Paper, Select, SelectChangeEvent, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Configuration, Device , DeviceApi, DeviceTypeEnum, Workplace, WorkplaceApi} from "../../generated/workplace";
import { useAuth } from "oidc-react";
import { useSnackbar } from "../../utils/snackbar";


interface ICreateWorkplaceProps {
    registerSubmit? : (fn: () => Promise<string | null | undefined>) => void;
}

const CreateWorkplace = (props:ICreateWorkplaceProps) => {

    const [workplace, setWorkplace] = useState<Workplace>({});
    
    const [tempTag, setTempTag] = useState<string>("");

    // const [id, setId] = useState<string>("");

    // const [tags, setTags] = React.useState<readonly string[]>([
    //     // "left",
    //     // "right"
    // ]);
    const showSnackbar = useSnackbar();


    const auth = useAuth();

    const access_token = auth.userData?.access_token;

    const createNewWorkplace = async () => {

        const workplaceApi = new WorkplaceApi(new Configuration({'accessToken': access_token!}));

        let result:Device|null = null;
        try {
            result = await workplaceApi.postWorkplace({workplace: workplace});
            
        } catch (error) {
            console.log("error postWorkplace", error);

            showSnackbar(`Workplace ${workplace.id} konnte nicht angelegt werden`, "error");

        }

        if(result){

            showSnackbar(`Workplace ${result.id} angelegt`, "success");

            return result.id;

        }
        
        // dummy debug
        return workplace.id;

        return null;
    }

    const createNewDeviceCallback = useCallback(createNewWorkplace, [access_token, workplace]);


    const { registerSubmit } = props;

    useEffect(() => {
        if(registerSubmit){
            registerSubmit(createNewDeviceCallback)
        }
    }, [createNewDeviceCallback, registerSubmit]);


    return (
        <div>
            CreateWorkplace
            
            <Grid container spacing={2} paddingTop={2}>

                <Grid item xs={12}>
                    <Box>
                        <TextField 
                            name="id" 
                            label="ID" 
                            variant="outlined" 
                            onChange={(event) => {
                                const value = event.target.value;

                                // setId(value);

                                setWorkplace((oldDevice) => {
                                    return {
                                        ...oldDevice,
                                        id: value
                                    }
                                });
                            }}

                            fullWidth
                            value={workplace.id}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box>
                        <TextField 
                            name="name" 
                            label="Name" 
                            variant="outlined" 
                            onChange={(event) => {
                                const value = event.target.value;

                                // setId(value);

                                setWorkplace((oldDevice) => {
                                    return {
                                        ...oldDevice,
                                        name: value
                                    }
                                });
                            }}

                            fullWidth
                            value={workplace.name}
                        />
                    </Box>
                </Grid>

            </Grid>



        </div>
    )
};

export default CreateWorkplace;
