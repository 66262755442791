import { Add, Delete } from "@mui/icons-material";
import { Backdrop, Box, Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridRowSelectionModel, GridRowsProp, GridToolbarContainer } from "@mui/x-data-grid";
import React, { useState } from "react";
import { Configuration, Device, DeviceApi, WorkplaceApi } from "../../generated/workplace";
import SelectDevice from "./selectDevice";
import { useAuth } from "oidc-react";
import { useSnackbar } from "../../utils/snackbar";

interface IDeviceListProps {
    items: Device[];

    saveNewDevicesToWorkplace: (newDevices:string[]) => void;
}

const DeviceList = (props:IDeviceListProps) => {
    const {items} = props;
    const [rows, setRows] = useState<GridRowsProp >(items);

    const showSnackbar = useSnackbar();

    const auth = useAuth();

    const access_token = auth.userData?.access_token;
   
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

    // const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    //   if (params.reason === GridRowEditStopReasons.rowFocusOut) {
    //     event.defaultMuiPrevented = true;
    //   }
    // };
  
    const handleEditClick = (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };
  
    const handleSaveClick = (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };
  
    const handleDeleteClick = (id: GridRowId) => async () => {

        const deleted = await deleteDevice(id.toString());

        if(deleted){
            // todo neu fetchen
    
            
            setRows(rows.filter((row) => row.id !== id));

        }
    };
  
    // const handleCancelClick = (id: GridRowId) => () => {
    //   setRowModesModel({
    //     ...rowModesModel,
    //     [id]: { mode: GridRowModes.View, ignoreModifications: true },
    //   });
  
    //   const editedRow = rows.find((row) => row.id === id);
    //   if (editedRow!.isNew) {
    //     // setRows(rows.filter((row) => row.id !== id));
    //   }
    // };
  
    // const processRowUpdate = (newRow: GridRowModel) => {
    //   const updatedRow = { ...newRow, isNew: false };
    // //   setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    //   return updatedRow;
    // };
  
    // const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    //   setRowModesModel(newRowModesModel);
    // };


    const deleteDevice = async (deviceId: string) => {

        const deviceApi = new DeviceApi(new Configuration({'accessToken': access_token!}));

        try {
            await deviceApi.deleteDevice({id: deviceId});

            showSnackbar(`Device ${deviceId} gelöscht`, "success");

            return true;
        } catch (error) {
            console.log("error postWorkplace", error);
                
        }

        return false;
    }


    const handleNewRows = async (newRows: GridRowSelectionModel) => {
        // save new DEvice IDs to Workplace


        props.saveNewDevicesToWorkplace(newRows as string[]);

    }
    
    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 300
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 200,
            editable: true,
        },

        {
            field: 'tags',
            headerName: 'Tags',
            width: 200,
            editable: true,
        },
        

        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
    
            // if (isInEditMode) {
            //     return [
            //     <GridActionsCellItem
            //         icon={<Save />}
            //         label="Save"
            //         sx={{
            //         color: 'primary.main',
            //         }}
            //         onClick={handleSaveClick(id)}
            //     />,
            //     <GridActionsCellItem
            //         icon={<Cancel/>}
            //         label="Cancel"
            //         className="textPrimary"
            //         onClick={handleCancelClick(id)}
            //         color="inherit"
            //     />,
            //     ];
            // }
    
            return [
                // <GridActionsCellItem
                //     icon={<Edit />}
                //     label="Edit"
                //     className="textPrimary"
                //     onClick={handleEditClick(id)}
                //     color="inherit"
                // />,
                <GridActionsCellItem
                    icon={<Delete />}
                    label="Delete"
                    onClick={handleDeleteClick(id)}
                    color="inherit"
                />,
            ];
            },
        },
    ];

    return (
        <Card 
            sx={{ padding: { xs: '10px', md: '16px' } }}
        >
            <Grid container alignItems={'center'} justifyContent={'center'} textAlign={'center'} spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            // initialState={{
                            //     pagination: {
                            //         paginationModel: {
                            //         pageSize: 5,
                            //         },
                            //     },
                            // }}
                            // pageSizeOptions={[5]}

                            checkboxSelection
                            disableRowSelectionOnClick

                            slots={{
                                toolbar: EditToolbar,
                            }}
                            slotProps={{
                                toolbar: { handleNewRows },
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Card>
        // <div >
        //     WorkplaceList

            

        //     {/* {items.map((item) => {
        //         return (
        //             <WorkplaceListItem item={item} />
        //         );
        //     })} */}
        // </div>
    )
};

export default DeviceList;




interface EditToolbarProps {
    // setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    // setRowModesModel: (
    //     newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    // ) => void;


    handleNewRows: (newRows: GridRowSelectionModel) => void;

}

function EditToolbar(props: EditToolbarProps) {
    // const { setRows, setRowModesModel } = props;

    const [open, setOpen] = React.useState(false);

    const [selected, setSelected] = useState<GridRowSelectionModel>();

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleClick = () => {
        // // const id = randomId();
        // const id = -1;

        // setRows((oldRows) => [...oldRows, { id, name: '', age: '', isNew: true }]);
        // setRowModesModel((oldModel) => ({
        //     ...oldModel,
        //     [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
        // }));

        setOpen(true);
    };

    const renderSelectDialog = () => {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                // PaperProps={{
                //     component: 'form',
                //     onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                //         event.preventDefault();
                //         const formData = new FormData(event.currentTarget);
                //         const formJson = Object.fromEntries((formData as any).entries());
                //         const email = formJson.email;
                //         console.log(email);
                //         handleClose();
                //     },
                // }}
                fullWidth
                maxWidth="xl"
            >
                <DialogTitle>Device-Auswahl</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Bitte ein Device auswählen.
                    </DialogContentText>
                    <SelectDevice onSelectionChanged={(selected) => {
                        

                        console.log("onSelectionChanged", selected);

                        setSelected(selected)
                        


                    }}/>

                </DialogContent>
                <DialogActions>
                    <Button 
                        color="primary"
                        variant="outlined"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>

                    <Button 
                        color="primary"
                        variant="contained"
                        type="button" 
                        onClick={async () => {

                            if(selected && selected.length > 0){
                                await props.handleNewRows(selected);

                            }

                            handleClose();

                        }}
                    >Select Device</Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<Add />} onClick={handleClick}>
                Assign Device
            </Button>

            {renderSelectDialog()}
        </GridToolbarContainer>
    );
}



