import { useAuth } from "oidc-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Configuration, Device, DeviceApi, WorkplaceApi } from "../../generated/workplace";
import { DataGrid, GridActionsCellItem, GridColDef, GridRowModesModel, GridRowSelectionModel, GridRowsProp, GridToolbarContainer } from "@mui/x-data-grid";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { Add } from "@mui/icons-material";
import CreateDevice from "./CreateDevice";

interface ISelectDeviceProps {
    onSelectionChanged?: (selected:GridRowSelectionModel) => void;
}

const SelectDevice = (props:ISelectDeviceProps) => {

    const auth = useAuth();

    const access_token = auth.userData?.access_token;

    // const [devices, setDevices] = useState<Device[]>();

    
    const [rows, setRows] = useState<GridRowsProp>();

    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 300
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 200,
            editable: true,
        },

        {
            field: 'tags',
            headerName: 'Tags',
            width: 200,
            editable: true,
        },
        

        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
            // const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
    
            // if (isInEditMode) {
            //     return [
            //     <GridActionsCellItem
            //         icon={<Save />}
            //         label="Save"
            //         sx={{
            //         color: 'primary.main',
            //         }}
            //         onClick={handleSaveClick(id)}
            //     />,
            //     <GridActionsCellItem
            //         icon={<Cancel/>}
            //         label="Cancel"
            //         className="textPrimary"
            //         onClick={handleCancelClick(id)}
            //         color="inherit"
            //     />,
            //     ];
            // }
    
            return [
                // <GridActionsCellItem
                //     icon={<Edit />}
                //     label="Edit"
                //     className="textPrimary"
                //     onClick={handleEditClick(id)}
                //     color="inherit"
                // />,

                // <GridActionsCellItem
                //     icon={<Delete />}
                //     label="Delete"
                //     onClick={handleDeleteClick(id)}
                //     color="inherit"
                // />,
            ];
            },
        },
    ];

    const fetchData = useCallback(
        async () => {
            const deviceApi = new DeviceApi(new Configuration({'accessToken': access_token!}));
            
            
            const workplaceApi = new WorkplaceApi(new Configuration({'accessToken': access_token!}));

            let allDevices:Device[] = [];

            try {
                // allDevices = await deviceApi.getAllDevices();


                // dummy Fake Daten
                const dummy =  await workplaceApi.getWorplace({id: 'n40.gb1b08112c14h0895'});
                if(dummy.devices){
                    allDevices = dummy.devices;
                }



            } catch (error) {
                console.log("error fetchData get", error);
            }

            if(allDevices != null){
                setRows(allDevices);
            }
        },
        [access_token]
    );


    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handleNewRows = () => {
        // save new DEvice IDs to Workplace

        fetchData()

    }

    if(rows == null){
        return (
            <div>
                no items
            </div>
        );
    }

    return (
        <div>
            

            <DataGrid
                autoHeight
                rows={rows}
                columns={columns}
                // initialState={{
                //     pagination: {
                //         paginationModel: {
                //         pageSize: 5,
                //         },
                //     },
                // }}
                // pageSizeOptions={[5]}

                checkboxSelection={props.onSelectionChanged != null}
                disableRowSelectionOnClick

                slots={{
                    toolbar: EditToolbar,
                }}
                slotProps={{
                    toolbar: { handleNewRows },
                }}

                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);

                    if(props.onSelectionChanged){
                        props.onSelectionChanged(newRowSelectionModel)
                    }
                }}
                rowSelectionModel={rowSelectionModel}
            />
        </div>
    )
};

export default SelectDevice;


interface EditToolbarProps {
    // setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    // setRowModesModel: (
    //     newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    // ) => void;


    handleNewRows: () => void;

}

function EditToolbar(props: EditToolbarProps) {
    // const { setRows, setRowModesModel } = props;

    const [open, setOpen] = React.useState(false);

    const [selected, setSelected] = useState<GridRowSelectionModel>();

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleClick = () => {
        // // const id = randomId();
        // const id = -1;

        // setRows((oldRows) => [...oldRows, { id, name: '', age: '', isNew: true }]);
        // setRowModesModel((oldModel) => ({
        //     ...oldModel,
        //     [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
        // }));

        setOpen(true);
    };


    const submitRef = useRef<() => Promise<string | null | undefined>>();

    const renderSelectDialog = () => {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                // PaperProps={{
                //     component: 'form',
                //     onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                //         event.preventDefault();
                //         const formData = new FormData(event.currentTarget);
                //         const formJson = Object.fromEntries((formData as any).entries());
                //         const email = formJson.email;
                //         console.log(email);
                //         handleClose();
                //     },
                // }}
                fullWidth
                maxWidth="xl"
            >
                <DialogTitle>New Device</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Neues Device Anlegen
                    </DialogContentText>
                    
                    <CreateDevice 
                        registerSubmit={(fn) => {
                            submitRef.current = fn;
                        }}
                    />
                    

                </DialogContent>
                <DialogActions>
                    <Button 
                        color="primary"
                        variant="outlined"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>

                    <Button 
                        color="primary"
                        variant="contained"
                        type="button" 

                        // disabled={submitRef.current == null}
                        
                        onClick={async () => {

                            // if(selected && selected.length > 0){
                            //     props.handleNewRows(selected);

                            // }


                            if(submitRef.current){
                                const newId = await submitRef.current();

                                if(newId == null){
                                    
                                    return;
                                }
                            }

                            props.handleNewRows();

                            handleClose();

                        }}
                    >Add Device</Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <GridToolbarContainer>
            <Button 
                color="primary" 
                startIcon={<Add />} 
                onClick={handleClick}
            >
                Add Device
            </Button>

            {renderSelectDialog()}
        </GridToolbarContainer>
    );
}



