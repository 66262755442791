import {useEffect, useMemo, useState} from 'react';
import { Box, Grid, Typography } from '@mui/material';
import radnabenmitte_img from '../../images/radnabenmitte_vertikal.png';
import { ArrowDownward, ArrowUpward, Done } from '@mui/icons-material';
import {Einstellwerte} from "../../generated/car";
import useLaserSocket from "../../components/laserSocket";
import { useSettingsStore, useStationStore } from '../../store/store';
import { useTranslation } from 'react-i18next';

const COLOR_WARNING = 'red';

const Radnabenmitte = () => {
    const { t } = useTranslation();
    
    const [refDistance, setRefDistance] = useState(1500);
    const [colorRight, setColorRight] = useState(COLOR_WARNING);
    const [colorLeft, setColorLeft] = useState(COLOR_WARNING);

    const setGlobalRIST = useSettingsStore(state => state.setRIST);
    const setGlobalLIST = useSettingsStore(state => state.setLIST);
    const setGlobalLIST_Phase2 = useSettingsStore(state => state.setLIST_Phase2);
    const setGlobalRIST_Phase2 = useSettingsStore(state => state.setRIST_Phase2);


    const stationStore = useStationStore(state => state.station);
    // const setStationStore = useStationStore(state => state.setStation);

    const stationId = stationStore?.id?.toLowerCase() || "";
    // const stationDevices = stationStore.devices;
    const stationDevices = useMemo(() => {
        return stationStore.devices;
    }, [JSON.stringify(stationStore.devices)]);


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {distance: leftDistance, connect : leftConnect, disconnect: leftDisconnect} = useLaserSocket(stationId, 1);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {distance: rightDistance, connect : rightConnect, disconnect: rightDisconnect} = useLaserSocket(stationId, 1);

    const [iconLeft, setIconLeft] = useState(<ArrowDownward sx={{height:'7vh', width:'5vw', color:COLOR_WARNING}} />);
    const [iconRight, setIconRight] = useState(<ArrowDownward sx={{height:'7vh', width:'5vw', color:COLOR_WARNING}} />);
    // const [mac, setMac] = useState({'left': '', 'right': ''});
    const [phase, setPhase] = useState('Phase 1');

    let einstellwerte: Einstellwerte = useMemo(() => {
        let einstellwert: Einstellwerte = {};
        
        try {
            const json = localStorage.getItem("einstellwert");
            if(json){
                einstellwert = JSON.parse(json);
            }
        } catch (error) {
            
        }

        return einstellwert;

    }, []);


    useEffect(() => {
        console.log("mount radnabe");
        
    }, []);

    
    useEffect(() => {
        console.log("changed stationDevices", stationDevices);
        
    }, [stationDevices]);


    useEffect(() => {
        console.log("changed leftConnect", leftConnect);
        
    }, [leftConnect]);

    useEffect(() => {
        console.log("changed rightConnect", rightConnect);
        
    }, [rightConnect]);


    useEffect(() => {

        if(stationDevices){
            for (const dev of stationDevices) {
                if(dev.type === "Distance" && dev.tags?.includes("left")){
                    leftConnect(dev);
                }
    
                if(dev.type === "Distance" && dev.tags?.includes("right")){
                    rightConnect(dev);
                }
            }

        }
        

    }, [leftConnect, rightConnect, stationDevices]);


    useEffect(() => {
        const test = localStorage.getItem("Phase");
        
        if(test != null && test !== phase){
            setPhase(test);
        }

    }, [phase]);
    
    useEffect(() => {
        console.log("changed einstellwerte", einstellwerte);
    
        if (einstellwerte != null) {
            if (einstellwerte.werte != null) {
                let werte = JSON.parse(einstellwerte.werte);

                

                if(phase === "Phase 1"){
                    if(werte['Abstand_Kalibriertafel'] != null){
                    
                        if(typeof werte['Abstand_Kalibriertafel'] === 'string'){
                            setRefDistance(parseInt(werte['Abstand_Kalibriertafel'].substring(0, werte['Abstand_Kalibriertafel'].length - 2)));
                        }else{
                            setRefDistance(parseInt(werte['Abstand_Kalibriertafel']));
                        }
                    }
                }else{
                    if(werte['Abstand_Kalibriertafel_Phase2'] != null){
                    
                        if(typeof werte['Abstand_Kalibriertafel'] === 'string'){
                            setRefDistance(parseInt(werte['Abstand_Kalibriertafel_Phase2'].substring(0, werte['Abstand_Kalibriertafel_Phase2'].length - 2)));
                        }else{
                            setRefDistance(parseInt(werte['Abstand_Kalibriertafel_Phase2']));
                        }
                    }
                }
                
            }
        }

        }, [einstellwerte, phase]);

    useEffect(() => {
        
        const rightDistanceStr = String(rightDistance).replaceAll(".", "");

        const leftDistanceStr = String(leftDistance).replaceAll(".", "");

        if(parseInt(rightDistanceStr) >= refDistance - 20 && parseInt(rightDistanceStr) <= refDistance + 20){
            setColorRight('green');
            setIconRight(<Done sx={{height: '7vh', width: '5vw', color: 'green'}}/>);
        }else{
            setColorRight(COLOR_WARNING);
            if(parseInt(rightDistanceStr) > refDistance) {
                setIconRight(<ArrowUpward sx={{height: '7vh', width: '5vw', color: COLOR_WARNING}}/>);
            }else{
                setIconRight(<ArrowDownward sx={{height: '7vh', width: '5vw', color: COLOR_WARNING}}/>);
            }
        }

        if(parseInt(leftDistanceStr) >= refDistance - 20 && parseInt(leftDistanceStr) <= refDistance + 20){
            setColorLeft('green');
            setIconLeft(<Done sx={{height: '7vh', width: '5vw', color: 'green'}}/>);
        }else{
            setColorLeft(COLOR_WARNING);
            if(parseInt(leftDistanceStr) > refDistance) {
                setIconLeft(<ArrowUpward sx={{height: '7vh', width: '5vw', color: COLOR_WARNING}}/>);
            }else{
                setIconLeft(<ArrowDownward sx={{height: '7vh', width: '5vw', color: COLOR_WARNING}}/>);
            }
        }

        if(phase === "Phase 1"){
            setGlobalRIST(rightDistance + " mm");
            setGlobalLIST(leftDistance + " mm");

        }else if(phase === "Phase 2"){
            setGlobalRIST_Phase2(rightDistance + " mm");
            setGlobalLIST_Phase2(leftDistance + " mm");
        }
        else{
            setGlobalRIST(rightDistance + " mm");
            setGlobalLIST(leftDistance + " mm");
        }
        

    }, [rightDistance, leftDistance, refDistance, phase, setGlobalRIST, setGlobalLIST, setGlobalRIST_Phase2, setGlobalLIST_Phase2]);

    return (
        <Box sx={{ backgroundColor: 'lightgrey', width: '100vw', height: '100vh', justifyContent: 'center', alignContent: 'center', textAlign:'center', verticalAlign:'middle' }}>
            <Box sx={{height: '3vh'}}></Box>
                <Typography variant='h3' >{phase}</Typography>
                <Grid container direction={'row'} textAlign={'center'} justifyContent={'center'} alignItems={'center'} spacing={0}>
                    <Grid item xs={3}>
                        <Typography variant='h3' >{t("laserRight")}:</Typography>
                        <Typography variant='h3' >{t("targetValue")}: {refDistance} mm</Typography>
                        <Typography variant='h3'>
                            <span>{t("actualValue")}:</span>{' '}
                            <span style={{color: colorRight}} >{rightDistance} mm</span>
                        </Typography>
                        {iconRight}
                    </Grid>
                    <Grid item xs={6} >
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <img src={radnabenmitte_img} style={{ width: "40vh", height: "80vh",transform:'rotate(180deg)' }} alt={t("wheelHubCenter") || "wheelHubCenter"} />
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant='h3' >{t("laserLeft")}:</Typography>
                        <Typography variant='h3' >{t("targetValue")}: {refDistance} mm</Typography>
                        <Typography variant='h3'>
                            <span>{t("actualValue")}:</span>{' '}
                            <span style={{color: colorLeft}} >{leftDistance} mm</span>
                        </Typography>
                        {iconLeft}
                    </Grid>
                </Grid>
        </Box>
    )
}


export default Radnabenmitte;