import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import WorkplaceDetail from "../../components/workplace/workplaceDetail";
import { Configuration, Device, Workplace, WorkplaceApi } from "../../generated/workplace";
import { useAuth } from "oidc-react";
import { Box, Grid, TextField } from "@mui/material";

interface IWorkplacePageProps {

}

const WorkplacePage = (props:IWorkplacePageProps) => {

    const {id} = useParams();

    const auth = useAuth();

    const access_token = auth.userData?.access_token;

    const [workplace, setWorkplace] = useState<Workplace>();

    useEffect(() => {
        const fetchData = async () => {

            if(id){
                const worklaceApi = new WorkplaceApi(new Configuration({'accessToken': access_token!}));
    
                let wp:Workplace | null = null;
    
                try {
                    // const workplaces = await worklacApi.getAllWorkplaces();
                    wp = await worklaceApi.getWorplace({id: id});
                } catch (error) {
                    console.log("error fetchData getAllWorkplaces", error);
                }
    
                if(wp != null){
                    setWorkplace(wp);
                }
            }
        }

        fetchData();
    }, [access_token, id])


    const saveNewDevicesToWorkplace = async (newDevices:string[]) => {
        if(workplace?.id){
            const worklaceApi = new WorkplaceApi(new Configuration({'accessToken': access_token!}));
    
            try {
                await worklaceApi.addDevicesToWorkplace({id: workplace?.id, devices:newDevices});

            } catch (error) {
                
            }

        }

    }


    if(workplace == null){
        return (
            <div>
                no item
            </div>
        );
    }


    return (
        <Grid container alignItems={'center'} justifyContent={'center'} textAlign={'center'} spacing={0} sx={{ backgroundColor: 'lightgrey' }}>
            <Grid  item xs={12} justifyContent={'center'} alignItems={'center'}>
                WorkplacePage 
                
                <Grid container spacing={2} paddingTop={2}>

                    <Grid item xs={12}>
                        <Box>
                            <TextField 
                                name="id" 
                                label="ID" 
                                variant="outlined" 
                                // onChange={(event) => {
                                //     const value = event.target.value;


                                //     setWorkplace((oldWorkplace) => {
                                //         return {
                                //             ...oldWorkplace,
                                //             id: value
                                //         }
                                //     });
                                // }}

                                fullWidth
                                value={workplace.id}

                                disabled={true}
                            />

                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box>

                            <TextField 
                                name="name" 
                                label="Name" 
                                variant="outlined" 
                                // onChange={(event) => {
                                //     const value = event.target.value;


                                //     setWorkplace((oldWorkplace) => {
                                //         return {
                                //             ...oldWorkplace,
                                //             name: value
                                //         }
                                //     });
                                // }}

                                fullWidth
                                value={workplace.name}

                                disabled={true}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box>
                            <div>
                                {workplace.tags}
                            </div>

                        </Box>
                    </Grid>

                    {/* <Grid item xs={12}>
                        <Box>
                            <div>
                                {JSON.stringify(workplace.devices, null, 2)}

                            </div>

                        </Box>
                    </Grid> */}

                </Grid>


                
                


                <WorkplaceDetail 
                    item={workplace} 
                    saveNewDevicesToWorkplace={saveNewDevicesToWorkplace}
                />
            </Grid>
        </Grid>
        
    )
};

export default WorkplacePage;
