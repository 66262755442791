
// export {}

import { Box, Button, Card, Divider, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { Einstellwerte, EinstellwerteApi, Kba, KbaApi, Manufacturer, ManufacturerApi, Models, ModelsApi, Type, TypeApi } from "../generated/car";
import { useConfigStore, useSettingsStore, useStationStore, useSuperJobStore, useValueStore } from "../store/store";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { JobApi } from "../generated/job";
import { SuperJob } from "../generated/pdf";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const DatabaseSelection = () => {

    const { t } = useTranslation();

    const hasLicense = useConfigStore(state => state.hasLicense);

    
    const [kba1, setKBA1] = useState("");
    const [kba2, setKBA2] = useState("");
    
    const [selectedType, setSelectedType] = useState("");

    const [einstellwerte, setEinstellwerte] = useState<Einstellwerte[]>([]);

    const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);
    const [selectedManufacturer, setSelectedManufacturer] = useState("");
    const [models, setModels] = useState<Models[]>([]);
    const [selectedModel, setSelectedModel] = useState("");
    const [types, setTypes] = useState<Type[]>([]);
    
    const [selectedEinstellwert, setSelectedEinstellwert] = useState<string>("");
    const [messTyp, setMessTyp] = useState("");
    const [targetId, setTargetId] = useState("");
    const [isDisabled, setDisabled] = useState(true)


    const navigate = useNavigate();

    let jobApi: JobApi = new JobApi();

    const superJob = useSuperJobStore(state => state.superJob);
    const updateSuperJob = useSuperJobStore(state => state.updateSuperJob);

    const globalMessTyp = useValueStore(state => state.messtyp);
    const setGlobalMessTyp = useValueStore(state => state.updateMesstyp);

    
    const stationStore = useStationStore(state => state.station);
    // const setStationStore = useStationStore(state => state.setStation);

    // const stationId = stationStore?.id?.toLowerCase();
    const stationDevices = stationStore.devices;

    const globalJobId = useSettingsStore(state => state.jobId);
    const setGlobalJobId = useSettingsStore(state => state.setJobId);

    const setGlobalHIST = useSettingsStore(state => state.setHIST);
    const setGlobalLIST = useSettingsStore(state => state.setLIST);
    const setGlobalRIST = useSettingsStore(state => state.setRIST);

    const setGlobalCalibration = useSettingsStore(state => state.setCalibration);



    const selectManufacturer = (event: SelectChangeEvent) => {
        console.log("selected: " + event.target.value);
        setSelectedManufacturer(event.target.value);
    }
    const selectModel = (event: SelectChangeEvent) => {
        setSelectedModel(event.target.value);
    }
    const selectType = (event: SelectChangeEvent) => {
        setSelectedType(event.target.value);
    }
    const selectEinstellwert = (event: SelectChangeEvent) => {
        setSelectedEinstellwert(event.target.value);
        console.log(event.target.value);
        setDisabled(true)

        for (const einstellwert in einstellwerte) {
            // @ts-ignore
            let ew = einstellwerte.at(einstellwert);
            console.log(einstellwert);
            console.log(einstellwerte);
            if (ew !== undefined) {
                // @ts-ignore
                if (ew.id === event.target.value) {
                    setGlobalCalibration(ew.EinstellArt!.bezeichnung!);

                    localStorage.setItem("einstellwert", JSON.stringify(ew));
                    localStorage.setItem("kalibrierart", ew.EinstellArt!.bezeichnung!);
                    setDisabled(false);
                    if(ew.messTypId === 3){
                        setMessTyp("Frontkamera");
                    }else if(ew.messTypId === 2){
                        setMessTyp("Distanzregelung");
                    }

                    // if(ew.messTypId === 0){
                    //     if(ew.EinstellArt?.bezeichnung?.indexOf("Frontkamera") !== -1){
                    //         setMessTyp("Frontkamera");
                    //     }
                    //     if(ew.EinstellArt?.bezeichnung?.indexOf("Distanzregelung") !== -1){
                    //         setMessTyp("Distanzregelung");
                    //     }
                    // }

                    
                    setTargetId(String(ew.kalibriertafelID!));
                }
                if(ew.EinstellArt!.bezeichnung! === 'Einstellwerte - Frontkamera'){
                    const anleitungFrontkamera = einstellwerte.find(k => k.EinstellArt?.bezeichnung === 'Kalibrierung - Frontkamera');
                    localStorage.setItem("anleitungFrontkamera", JSON.stringify(anleitungFrontkamera));
                }
                if(ew.EinstellArt!.bezeichnung! === 'Einstellwerte - Distanzregelung'){
                    const anleitungDistanzregelung = einstellwerte.find(k => k.EinstellArt?.bezeichnung === 'Kalibrierung - Distanzregelung');
                    localStorage.setItem("anleitungDistanzregelung", JSON.stringify(anleitungDistanzregelung));
                }
            }
        }

    }


    const searchForKBA = () => {
        let kbaApi: KbaApi = new KbaApi();
        kbaApi.getForKba({
            "kba1": kba1,
            "kba2": kba2,
        }).then((data) => {
            console.log(data);

            if(data !== undefined && data.manufacturerId !== undefined && data.modelId !== undefined && data.typeId !== undefined){
                const modelIdString: string = data.modelId.toString();
                const typeIdString: string = data.typeId.toString();


                setSelectedManufacturer(data.manufacturerId.toString());
                setSelectedModel(modelIdString);
                setSelectedType(typeIdString);
                //setTimeout(() => setSelectedManufacturer(data.manufacturerId), 0);
                //setSelectedModel(data.modelId.toString());

                setTimeout(() => setSelectedModel(modelIdString), 1000);
                setTimeout(() => setSelectedType(typeIdString), 2000);
                //setSelectedType(data.typeId.toString());
            }

        });
    }

    const doSearch = () => {
        let hsn: string = localStorage.getItem("kba1") ?? "";
        let name: string = localStorage.getItem("kba2") ?? "";

        const jobId = globalJobId > 0 ? globalJobId : undefined;

        jobApi.postJob({
            "job": {
                "id": jobId,
                "hsn": hsn.length !== 0 ? hsn : "YYY",
                "name": name.length !== 0 ? name : "YYY",
                "vin": "ABCDEFGHIJ",
                "state": "Created",
                "additionalInfo": [{"name": "target", "value": targetId.toString()}]
            }
        }).then(
            (data) => {
                if (data.id !== undefined) {
                    setGlobalJobId(data.id);
                    jobApi.changeState({
                        "id": data.id,
                        "state": "CalibrateDistance"
                    })

                    if(stationDevices){
                        for (const dev of stationDevices) {
                            if(dev.type === "Gateway" && dev.id != null){
                                jobApi.postMarry({
                                    "id": data.id,
                                    "device": dev.id,
                                }).then(r => console.log(r))
                            }
                        }
                    }

                }
            }
        );

        let sj: SuperJob = superJob;

        sj.hsn  = hsn.length !== 0 ? hsn : "YYY";
        sj.name =  name.length !== 0 ? name : "YYY";

        let myew: Einstellwerte = {};
        try {
            const json = localStorage.getItem("einstellwert");
            if(json){
                myew = JSON.parse(json);
            }
        } catch (error) {
            
        }


        let jsonWerte: any;
        if(myew.werte !== undefined && myew.werte.length > 0) {
            jsonWerte = JSON.parse(myew.werte);
        }
        if(jsonWerte !== null && jsonWerte !== undefined) {
            sj.hsoll = jsonWerte['Höhe_Kalibriertafel'];
            sj.lsoll = jsonWerte['Abstand_Kalibriertafel'];
            sj.rsoll = jsonWerte['Abstand_Kalibriertafel'];
        }
        if(myew.kalibriertafelID != null) {
            sj.target = myew.kalibriertafelID.toString();
        }

        let hersteller = null;
        manufacturers.forEach(item =>{
            if(item.id === parseInt(selectedManufacturer)){
                hersteller = item.name;
            }
        })

        let modell = null;
        models.forEach(item => {
            if(item.id === parseInt(selectedModel)){
                modell = item.name;
            }
        })
        
        let typ = null;
        types.forEach(item => {
            if(item.id === parseInt(selectedType)){
                typ = item.name;
            }
        }) 
        

        let messtyp = null;
        einstellwerte.forEach(item => {
            if(String(item.id) === selectedEinstellwert){
                messtyp = item.messTypId;
            }
        })
        

        sj.fahrzeug = hersteller + " / " +  modell + " / " + typ;


        //Todo:  Welcher Typ ist hier richtig?
        console.log("MessTyp??: ", messtyp, messTyp);
        
        setGlobalMessTyp(messTyp);

        updateSuperJob(sj);

        setGlobalHIST("");
        setGlobalLIST("");
        setGlobalRIST("");

        console.log(sj);

        gotoCorrectPage();

    }



    useEffect(() => {
        localStorage.removeItem("kba1");
        localStorage.removeItem("kba2");
        setDisabled(true)

        let einstellwerteApi: EinstellwerteApi = new EinstellwerteApi();
        einstellwerteApi.getEinstellwerte({"modelId": Number(selectedType)}).then((data) => {
            setEinstellwerte(data);
        });
    }, [selectedType]);


    useEffect(() => {
        let kbaApi: KbaApi = new KbaApi();
        kbaApi.getKba({"manufacturerId": Number(selectedManufacturer), "modelId": Number(selectedModel), "typeId": Number(selectedType)}).then((data: Kba) => {
                localStorage.setItem("kba1", data.kba1!.toString());
                localStorage.setItem("kba2", data.kba2!.toString());
            }, (err) => {
                console.log("kba nicht in der DB");
            }
        )
    }, [selectedEinstellwert, selectedManufacturer, selectedModel, selectedType]);

    useEffect(() => {
        let manufacturerApi: ManufacturerApi = new ManufacturerApi();
        manufacturerApi.getManufacturer().then((data) => {
            console.log(data);
            setManufacturers(data);
            localStorage.removeItem("kba1");
            localStorage.removeItem("kba2");
            setDisabled(true)

        });

    }, []);
    useEffect(() => {
        let modelsApi: ModelsApi = new ModelsApi();
        modelsApi.getModels({"manufacturerId": Number(selectedManufacturer)}).then((data) => {
            console.log(data);
            setModels(data);
            localStorage.removeItem("kba1");
            localStorage.removeItem("kba2");
            setDisabled(true)

        });


    }, [selectedManufacturer]);

    useEffect(() => {
        let typeApi: TypeApi = new TypeApi();
        typeApi.getType({"modelId": Number(selectedModel)}).then((data) => {
            console.log(data);
            setTypes(data);
            localStorage.removeItem("kba1");
            localStorage.removeItem("kba2");
            setDisabled(true)


        });

    }, [selectedModel]);

    useEffect(() => {
        let kbaApi: KbaApi = new KbaApi();
        kbaApi.getKba({"manufacturerId": Number(selectedManufacturer), "modelId": Number(selectedModel), "typeId": Number(selectedType)}).then((data: Kba) => {
                localStorage.setItem("kba1", data.kba1!.toString());
                localStorage.setItem("kba2", data.kba2!.toString());
            }, (err) => {
                console.log("kba nicht in der DB");
            }
        )
    }, [selectedEinstellwert, selectedManufacturer, selectedModel, selectedType]);


    const gotoCorrectPage = (isDB: boolean = true)  => {
        // console.log(radioMesstyp);
        // if(isDB){
            navigate("/einstellwerte")
        // }else{
        //     localStorage.setItem("Phase", "Phase 1");
        //     switch (radioMesstyp){
        //         case 'Fahrzeugfront':
        //             navigate("/fahrzeugfront")
        //             break
        //         case 'Radnabenmitte':
        //             navigate("/radnabenmitte")
        //             break
        //         case 'Frontkamera':
        //             navigate("/frontkamera")
        //             break
        //         default:
        //             alert("Kein Messtyp ausgewählt")
        //     }
        // }

    }

    
    if(!hasLicense("database")){
        return null;
    }

    return (
        <Card 
            // style={{width: '99%', padding: '1%'}} 
            sx={{ padding: { xs: '10px', md: '16px' } }}
        >
            <Grid container alignItems={'center'} justifyContent={'center'} textAlign={'center'} spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ textAlign: 'center', height: '4vh' }}>
                        <Typography variant='h4'>{t("databaseSelection")}</Typography>
                    </Box>
                </Grid>
            </Grid>

            <Grid container item xs={12} spacing={2} alignItems={"center"} justifyContent={"center"}>
                <Grid item xs={12} md={12}>
                    <Box sx={{ textAlign: 'left' }}>
                        <Typography variant='h5' mt={2}>{t("kbaSearch")}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <TextField id="kba1" label={t("kba1")} variant="outlined" onChange={(event) => setKBA1(event.target.value)} fullWidth />
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                    <TextField id="kba2" label={t("kba2")} variant="outlined" onChange={(event) => setKBA2(event.target.value)} fullWidth/>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <Button variant={"contained"} onClick={searchForKBA} fullWidth style={{height: '5vh'}}>{t("search")}</Button>
                </Grid>
            </Grid>
            <Box sx={{height: '3vh'}}></Box>
            <Divider></Divider>
            
            <Grid container item xs={12} spacing={2} alignItems={"left"} justifyContent={"left"} >
            <Grid item xs={12} md={12}>
                <Box sx={{ textAlign: 'left', height: '4vh'}}>
                    <Typography variant='h5' mt={2}>{t("modelSearch")}</Typography>
                </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <FormControl fullWidth>
                        <InputLabel id="hersteller-label">{t("manufacturer")}</InputLabel>
                        <Select
                            labelId="hersteller-label"
                            value={selectedManufacturer}
                            input={<OutlinedInput label={t("manufacturer")} />}
                            MenuProps={MenuProps}
                            onChange={selectManufacturer}
                            >
                            {manufacturers.map((m) => {
                                return <MenuItem value={m.id} key={m.id}>{m.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <FormControl fullWidth>
                        <InputLabel id="modell-label">{t("model")}</InputLabel>
                        <Select
                            labelId="modell-label"
                            value={selectedModel}
                            input={<OutlinedInput label={t("model")} />}
                            MenuProps={MenuProps}
                            onChange={selectModel}
                            >
                            {models.map((m) => {
                                return <MenuItem value={m.id} key={m.id}>{m.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <FormControl fullWidth>
                        <InputLabel id="typ-label">{t("type")}</InputLabel>
                        <Select
                            labelId="typ-label"
                            value={selectedType}
                            input={<OutlinedInput label={t("type")} />}
                            MenuProps={MenuProps}
                            onChange={selectType}
                        >
                            {types.map((m) => {
                                return <MenuItem value={m.id} key={m.id}>{m.name}/{m.baujahrVon}/{m.baujahrBis}/{m.hubraum}/{m.motorcode}/{m.kw}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <FormControl fullWidth>
                        <InputLabel id="einstellwerte-label">{t("configValues")}</InputLabel>
                        <Select
                            labelId="einstellwerte-label"
                            input={<OutlinedInput label={t("configValues")} />}
                            value={selectedEinstellwert}
                            MenuProps={MenuProps}
                            onChange={selectEinstellwert}
                        >
                        {einstellwerte.map((m) => {
                            if(m.EinstellArt === undefined || m.EinstellArt.bezeichnung === undefined){
                                return null;
                            }
                            if(m.EinstellArt!.bezeichnung!.toLowerCase().includes("einstellwert")){
                                return <MenuItem key={m.id} value={m.id}>{m.EinstellArt?.bezeichnung}</MenuItem>
                            }
                            if(m.EinstellArt!.bezeichnung!.toLowerCase().includes("kalibrierung")) {
                                return <MenuItem key={m.id} value={m.id}>{m.EinstellArt?.bezeichnung}</MenuItem>
                            }

                            return null;
                        })}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Box sx={{height: '3vh'}}></Box>

            <Grid container item xs={12} spacing={2} alignItems={"center"} justifyContent={"center"} >
                <Grid item xs={12} md={6} lg={6}  >
                    <Box sx={{  alignContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                        <Button variant='contained' disabled={isDisabled} sx={{padding: '15px'}}  onClick={doSearch} fullWidth>
                            {t("start")}</Button>
                    </Box>
                </Grid>
                
            </Grid>
        </Card>
        
    );
 }


 export default DatabaseSelection;