/* tslint:disable */
/* eslint-disable */
/**
 * 
 * @export
 * @interface Device
 */
export interface Device {
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Device
     */
    tags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    type?: DeviceTypeEnum;
}


/**
 * @export
 */
export const DeviceTypeEnum = {
    Table: 'Table',
    Gateway: 'Gateway',
    Distance: 'Distance',
    UnknownDefaultOpenApi: '11184809'
} as const;
export type DeviceTypeEnum = typeof DeviceTypeEnum[keyof typeof DeviceTypeEnum];

/**
 * 
 * @export
 * @interface Workplace
 */
export interface Workplace {
    /**
     * Eindeutige id des Workplaces
     * @type {string}
     * @memberof Workplace
     */
    id?: string;
    /**
     * Name des Workplace
     * @type {string}
     * @memberof Workplace
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Workplace
     */
    tags?: Array<string>;
    /**
     * 
     * @type {Array<Device>}
     * @memberof Workplace
     */
    devices?: Array<Device>;
}
